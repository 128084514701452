import React from 'react';
import './NotFound.css';
import { Link } from 'react-router-dom';
import { useAppContext } from '../context/AppContext';
import { Button } from '@material-ui/core';
// import MiniDrawer from '../components/DashboardPage';

export default function NotFound({
  message = 'Sorry, this page does not exist.',
  showGoBackButton = true,
  mrc,
}) {
  const context = useAppContext();

  return (
    <>
      <div className={'not-found'}>
        <div
          style={{
            height: '70vh',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <h4 style={{ color: 'white' }}>{message}</h4>
          {showGoBackButton && context.appConfig.showStudyList && (
            <h5>
              <Link style={{ color: '#0a55a1' }} to={'#'}>
                Could not load Study
              </Link>
            </h5>
          )}
          <div style={{ marginTop: '20px' }}>
            {/* <Link to="/Studylist"> */}
            <Link to="/">
              <Button style={{ backgroundColor: '#0a55a1', color: 'white' }}>
                Home
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
