import { connect } from 'react-redux';

// import { setBucketpath } from '../../../redux/actions';
// import { bucketPath } from '../../../redux/reducers/bucketPath';

export default class RetrieveMetadataLoader {
  constructor(server, studyInstanceUID, filters = {}) {
    this.server = server;
    this.studyInstanceUID = studyInstanceUID;
    this.filters = filters;
  }
  async execLoad() {
    await this.configLoad();
    const preLoadData = await this.preLoad();
    const loadData = await this.load(preLoadData);
    const postLoadData = await this.posLoad(loadData);

    return postLoadData;
  }
  async runLoaders(loaders) {
    let bucketPaths = [];
    let result;
    for (const loader of loaders) {
      try {
        result = await loader();
        // console.log(result, 'resultttttt');
        // result.map((item) => {
        //   localStorage.setItem("BucketPath", item.BucketPath);
        // })
        // Dispatch the action using mapDispatchToProps
        // mapDispatchToProps.setBucketpath(result);
        if (Array.isArray(result)) {
          result.forEach(item => {
            if (item && item.BucketPath) {
              bucketPaths.push(item.BucketPath);
            }
          });
        } else if (result && result.BucketPath) {
          bucketPaths.push(result.BucketPath);
        }

        if (bucketPaths.length > 0) {
          const updateEvent = new CustomEvent('dataUpdated', {
            detail: bucketPaths,
          });
          window.dispatchEvent(updateEvent);
          break;
        }
      } catch (e) {
        throw e;
      }
    }

    //     if (result && result.length) {
    //       break;
    //     }
    //   } catch (e) {
    //     throw e;
    //   }
    // }

    if (loaders.next().done && !result) {
      throw new Error('RetrieveMetadataLoader failed');
    }

    return result;
  }

  async configLoad() {}
  async preLoad() {}
  async load(preLoadData) {}
  async posLoad(loadData) {}
}
// const mapStateToProps = (state, ownProps) => {
//   const { bucketPath, } = ownProps;
//   const { activebucketPath } = state.viewports;

//   return {
//     bucketPath,
//     activebucketPath
//   };
// };

// Map Redux state to component props
// const mapStateToProps = (state,ownProps) => {
//   const { bucketPath, byteArray } = ownProps;
//   const { bucketPath } = state.bucketPath;
//   return {
//     bucketPath
//   };
// };

// Map dispatch to component props
// const mapDispatchToProps = (dispatch,ownProps) => {
//   const { bucketPath } = ownProps;
//   return {
//     setBucketpath: () => {
//       dispatch(setBucketpath(bucketPath));
//       console.log(bucketPath,'bucketPathtttttttttt');
//     },
//   };
// };

//Connect the component to Redux
// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(RetrieveMetadataLoader);
