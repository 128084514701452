import React, { useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { ErrorBoundary, Icon } from '@dcmcloud/ui';
import { servicesManager } from './../../App';
import cogoToast from 'cogo-toast';
import { LoginApi } from '../../../../../Constants/baseURL';

const { UIModalService } = servicesManager.services;

const ReportBug = async (error, componentStack) => {
  const url = `${LoginApi}/api/v1/emailsend/sendEmailLink`;

  const emailBody = `
    A BUG HAS BEEN REPORTED

    ====================================

    ERROR DETAILS:
    ====================================
    Reason: ${error}

    ====================================

    COMPONENT STACK:
    ====================================
    ${componentStack}

    ====================================

    Please investigate this issue.
  `;

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        address: 'nixaamteammern@gmail.com',
        link: emailBody,
        subject: 'BUG REPORT FOR DCMCLOUD',
      }),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status} - ${response.statusText}`);
    }

    cogoToast.success('Bug report sent successfully', {
      position: 'bottom-right',
    });
  } catch (err) {
    cogoToast.error('Failed to send bug report', { position: 'bottom-right' });
  }
};

const ErrorBoundaryDialog = ({ context, children }) => {
  const handleOnError = (error, componentStack) => {
    const ErrorDialog = () => {
      const [open, setOpen] = useState(false);

      return (
        <div className="ErrorFallback flex flex-col items-center justify-center" role="alert">
          <div className="flex w-full justify-center items-center flex-col">
            <div className="bg-gray-800 p-5 w-full flex items-center justify-center rounded-t-lg shadow-lg">
              <span className="text-white text-lg font-semibold">
                Oops! Something went wrong in {context}. Please either report the bug, go to home, or log out.
              </span>
            </div>

            <div className="bg-black w-full overflow-y-auto p-4 border-2 border-gray-800 shadow-lg">
              <div className="text-red-600 font-semibold text-lg">
                Reason: {error.message}
              </div>
              {open && (
                <div className="text-white mt-1 whitespace-pre-wrap">
                  {componentStack}
                </div>
              )}
            </div>

            <div className="w-full flex items-center justify-between space-x-4 bg-gray-800 p-5 rounded-b-lg shadow-lg">
              <button
                onClick={() => {
                  localStorage.removeItem('userData');
                  localStorage.removeItem('token');
                  window.location.href = '/';
                }}
                className="bg-red-500 text-white py-2 px-4 rounded-lg hover:bg-red-600 transition-colors duration-200"
              >
                Log Out
              </button>
              <div className="flex space-x-4">
                <button
                  className="bg-yellow-500 text-white py-2 px-4 rounded-lg hover:bg-yellow-600 transition-colors duration-200"
                  onClick={() => setOpen(s => !s)}
                >
                  <Icon
                    name="chevron-down"
                    className={classnames('ErrorBoundaryDialogIcon', {
                      'rotate-180': open,
                    })}
                  />
                  Stack Trace
                </button>
                <button
                  onClick={() => {
                    window.location.href = '/';
                  }}
                  className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition-colors duration-200"
                >
                  Go to Home
                </button>
                <button
                  onClick={() => ReportBug(error.message, componentStack)}
                  className="bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-green-600 transition-colors duration-200"
                >
                  Report Bug
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    };

    if (UIModalService) {
      UIModalService.show({
        content: ErrorDialog,
        title: `Error in ${context}`,
        shouldCloseOnEsc: true,
        contentProps: {
          className: 'w-full max-w-4xl'
        }
      });
    }


  };

  const fallbackComponent = () => (
    <div className="ErrorFallback" role="alert">
      <p>
        Error rendering {context}. <br /> Check the browser console for more
        details.
      </p>
    </div>
  );

  return (
    <ErrorBoundary
      fallbackComponent={fallbackComponent}
      context={context}
      onError={handleOnError}
    >
      {children}
    </ErrorBoundary>
  );
};

ErrorBoundaryDialog.propTypes = {
  context: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default ErrorBoundaryDialog;
