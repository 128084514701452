import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Thumbnail } from './Thumbnail.js';
import './StudyBrowser.styl';

import AppContext from '../../../../viewer/src/context/AppContext.js';
import Icon from '../../elements/Icon/Icon.js';
import CryptoJS from 'crypto-js';
import { CoreApi, secretKeyy } from '../../../../../Constants/baseURL.js';

function StudyBrowser(props) {
  const { setSeriesDescription } = React.useContext(AppContext);
  const {
    studies,
    onThumbnailClick,
    onThumbnailDoubleClick,
    supportsDrag,
    showThumbnailProgressBar,
  } = props;

  const omClickThumbnail = SeriesDescription => {
    setSeriesDescription(SeriesDescription);
    // console.log('SeriesDescription', SeriesDescription);
  };

  // const omClickThumbnail = displaySetInstanceUID => {
  //   onThumbnailClick(displaySetInstanceUID);
  //   console.log('displaySetInstanceUID', displaySetInstanceUID);
  // };

  const [expandedCase, setExpandedCase] = useState([]); // Track which cases are expanded
  const [studiesForModality, setStudiesForModality] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const allIndexes = studies.map((_, index) => index); // Create an array of indexes [0, 1, 2, ...]
    setExpandedCase(allIndexes); // Set the expandedCase state to this array
  }, [studies]); // This effect runs when the studies array changes

  const toggleCase = studyIndex => {
    setExpandedCase(prevExpandedCase => {
      if (prevExpandedCase.includes(studyIndex)) {
        // Case is already expanded, so remove it (collapse it)
        return prevExpandedCase.filter(index => index !== studyIndex);
      } else {
        // Case is not expanded, so add it (expand it)
        return [...prevExpandedCase, studyIndex];
      }
    });
  };

  const loadStudyList = async () => {
    try {
      var token;
      var bucket;
      if (
        window.location.href.includes('/xrad/') ||
        window.location.href.includes('/radx/') ||
        window.location.href.includes('/mrc/')
      ) {
        token =
          'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY1YzljOWQ3ZDQ0MjA3N2M1NDkwYjljMSIsImlhdCI6MTcwNzg4Nzc1MSwiZXhwIjoxNzM5NDIzNzUxfQ.K6FAFdCLZ72WfTpvMon1yzWOns52QUp2c_2C6XP6Boo';
        bucket = {
          _id: '66c9b8c8ea86628757bf19b3',
          user: '65c9c9d7d442077c5490b9c1',
          bucketName: 'xrad-us',
          dcmcAccessKey: '98u3498u398u498',
          bucketType: 'aws',
          __v: 0,
          accessKeyId:
            'U2FsdGVkX1+O7YXTu6p8pRR/bC5/FCFwngE82om/ydLDPbd00HqJHWfM1OdOMfv1',
          secretAccessKey:
            'U2FsdGVkX1/RUqcNvEzoO39BtDfWnw8VzstaA8o1aTaX8xGR1k9vTNlpqLBFb4n4S6HNXc92zi4sCRXtseDq+g==',
        };
      } else {
        token = localStorage.getItem('token');
        bucket = JSON.parse(localStorage.getItem('currentbucket'));
      }

      const decrypted = {
        _id: bucket._id,
        user: bucket.user,
        bucketName: bucket.bucketName,
        dcmcAccessKey: bucket.dcmcAccessKey,
        bucketType: bucket.bucketType,
        __v: bucket.__v,
        accessKeyId: CryptoJS.AES.decrypt(
          bucket.accessKeyId,
          secretKeyy
        ).toString(CryptoJS.enc.Utf8),
        secretAccessKey: CryptoJS.AES.decrypt(
          bucket.secretAccessKey,
          secretKeyy
        ).toString(CryptoJS.enc.Utf8),
      };

      // console.log(decrypted);

      const response = await fetch(
        `${CoreApi}/coreapi/react/QidoRS/studies?limit=25&offset=0&fuzzymatching=true&includefield=00081030,00080060&AccessKeyId=${encodeURIComponent(
          decrypted.accessKeyId
        )}&SecretAccessKey=${encodeURIComponent(
          decrypted.secretAccessKey
        )}&BucketName=${decrypted.bucketName}&Region=us-east-1`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const studiesModality = await response.json();

      const newStudies = studiesModality
        .filter(item => {
          const studyUID =
            (item['0020000D'] && item['0020000D'].Value[0]) ||
            (item['0020000d'] && item['0020000d'].Value[0]);

          return studies.some(study => study.StudyInstanceUID === studyUID);
        })
        .map(study => {
          const studyUID =
            (study['0020000D'] && study['0020000D'].Value[0]) ||
            (study['0020000d'] && study['0020000d'].Value[0]);

          const patientModality =
            (study['00080060'] &&
              study['00080060'].Value &&
              study['00080060'].Value[0]) ||
            '';

          return {
            studyUID: studyUID,
            modality: patientModality,
          };
        });

      setStudiesForModality(newStudies);
      setIsLoading(false);
    } catch (error) {
      console.log('Error loading study list:', error);
      setIsLoading(false);
    }
  };

  const spinner = () => {
    return <div className="spinner mt-1 ml-3"></div>;
  };

  useEffect(() => {
    if (studies.length > 1) {
      setIsLoading(true);
      loadStudyList();
    }
  }, []);

  const removeStudies = (e, studyUID) => {
    e.stopPropagation();
    let currentUrl = window.location.href;

    if (currentUrl.includes(studyUID)) {
      let updatedUrl = currentUrl.replace(`${studyUID}`, '');
      if (updatedUrl.includes('/&')) {
        updatedUrl = updatedUrl.replace('/&', '/');
      } else if (updatedUrl.includes('&&')) {
        updatedUrl = updatedUrl.replace('&&', '&');
      } else if (updatedUrl.includes('&%5E')) {
        updatedUrl = updatedUrl.replace('&%5E', '%5E');
      }
      window.location.href = updatedUrl;
    }
  };

  return (
    <div className="study-browser" style={{ overflow: 'auto', width: '95%' }}>
      <div className="scrollable-study-thumbnails">
        {studies.map((study, studyIndex) => {
          const { StudyInstanceUID } = study;

          return (
            <div key={StudyInstanceUID}>
              {/* Display the case number at the start of each study */}
              {studies.length > 1 && (
                <div
                  className={`
                  flex items-center justify-between
                  text-lg font-semibold
                  hover:bg-gray-300
                  border-gray-300 border-b
                  p-3 my-3
                  cursor-pointer transition duration-300 ease-in-out
                `}
                  style={{ width: '170px' }}
                  onClick={() => toggleCase(studyIndex)} // Toggle case on click
                >
                  <div>
                    <span className="flex">
                      Case {isLoading && spinner()}
                      {(() => {
                        const foundStudy = studiesForModality.find(
                          study => study.studyUID === StudyInstanceUID
                        );
                        if (foundStudy) {
                          return '(' + foundStudy.modality + ')';
                        } else {
                          return ''; // return a fallback value like an empty string or some default text
                        }
                      })()}
                    </span>
                    {studies.length - 1 == studyIndex ? (
                      <span className="text-sm font-normal"> Original</span>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="flex">
                    <div
                      style={{
                        paddingTop: '2px',
                      }}
                      className={`transform transition-transform duration-300 ${
                        expandedCase.includes(studyIndex)
                          ? 'rotate-180'
                          : 'rotate-0'
                      }`}
                    >
                      <Icon className="text-sm" name="chevron-down" />
                    </div>
                    <span
                      title="Remove Study from Comparison"
                      className="ml-3 hover:bg-gray-400 rounded-full"
                      onClick={e => removeStudies(e, StudyInstanceUID)}
                    >
                      <Icon
                        className="text-sm text-red-500"
                        name="clearall"
                        style={{ width: '18px', height: '18px' }}
                      />
                    </span>
                  </div>
                </div>
              )}

              {/* Only show thumbnails if the case is expanded */}
              {expandedCase.includes(studyIndex) && (
                <div>
                  {study.thumbnails.map((thumb, thumbIndex) => {
                    const {
                      active,
                      altImageText,
                      displaySetInstanceUID,
                      imageId,
                      InstanceNumber,
                      numImageFrames,
                      SeriesDescription,
                      SeriesNumber,
                      stackPercentComplete,
                      hasWarnings,
                    } = thumb;

                    return (
                      <div
                        key={thumb.displaySetInstanceUID}
                        className="thumbnail-container"
                        data-cy="thumbnail-list"
                      >
                        <Thumbnail
                          active={active}
                          supportsDrag={supportsDrag}
                          key={`${studyIndex}_${thumbIndex}`}
                          id={`${studyIndex}_${thumbIndex}`} // Unused?
                          // Study
                          StudyInstanceUID={StudyInstanceUID} // used by drop
                          // Thumb
                          altImageText={altImageText}
                          imageId={imageId}
                          InstanceNumber={InstanceNumber}
                          displaySetInstanceUID={displaySetInstanceUID} // used by drop
                          numImageFrames={numImageFrames}
                          SeriesDescription={SeriesDescription}
                          SeriesNumber={SeriesNumber}
                          hasWarnings={hasWarnings}
                          stackPercentComplete={stackPercentComplete}
                          // Events
                          onClick={() => {
                            // Existing function call
                            onThumbnailClick(displaySetInstanceUID);

                            // Add your additional function call here
                            omClickThumbnail(SeriesDescription);
                          }}
                          showProgressBar={showThumbnailProgressBar}
                          onDoubleClick={onThumbnailDoubleClick}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

const noop = () => {};

StudyBrowser.propTypes = {
  studies: PropTypes.arrayOf(
    PropTypes.shape({
      StudyInstanceUID: PropTypes.string.isRequired,
      thumbnails: PropTypes.arrayOf(
        PropTypes.shape({
          altImageText: PropTypes.string,
          displaySetInstanceUID: PropTypes.string.isRequired,
          imageId: PropTypes.string,
          InstanceNumber: PropTypes.number,
          numImageFrames: PropTypes.number,
          SeriesDescription: PropTypes.string,
          SeriesNumber: PropTypes.number,
          stackPercentComplete: PropTypes.number,
        })
      ),
    })
  ).isRequired,
  supportsDrag: PropTypes.bool,
  onThumbnailClick: PropTypes.func,
  onThumbnailDoubleClick: PropTypes.func,
};

StudyBrowser.defaultProps = {
  studies: [],
  supportsDrag: true,
  onThumbnailClick: noop,
  onThumbnailDoubleClick: noop,
  showThumbnailProgressBar: true,
};

export { StudyBrowser };
