// TODO: A way to add Icons that don't already exist?
// - Register them and add
// - Include SVG Source/Inline?
// - By URL, or own component?

// What KINDS of toolbar buttons do we have...
// - One's that dispatch commands
// - One's that set tool's active
// - More custom, like CINE
//    - Built in for one's like this, or custom components?

// Visible?
// Disabled?
// Based on contexts or misc. criteria?
//  -- ACTIVE_ROUTE::VIEWER
//  -- ACTIVE_VIEWPORT::CORNERSTONE
// setToolActive commands should receive the button event that triggered
// so we can do the "bind to this button" magic

const TOOLBAR_BUTTON_TYPES = {
  COMMAND: 'command',
  SET_TOOL_ACTIVE: 'setToolActive',
  BUILT_IN: 'builtIn',
};

const TOOLBAR_BUTTON_BEHAVIORS = {
  CINE: 'CINE',
  DOWNLOAD_SCREEN_SHOT: 'DOWNLOAD_SCREEN_SHOT',
  ARROW_ANNOTATE: 'ARROW_ANNOTATE',
};

/* TODO: Export enums through a extension manager. */
const enums = {
  TOOLBAR_BUTTON_TYPES,
  TOOLBAR_BUTTON_BEHAVIORS,
};

const codetest = () => {
  let buttons = [
    {
      id: 'StackScroll',
      class: 'stackscrolll',
      label: 'Stack Scroll',
      // icon: 'bars',
      icon: 'Filter',
      //
      type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
      commandName: 'setToolActive',
      commandOptions: { toolName: 'StackScroll' },
    },
    {
      id: 'Zoom',
      label: 'Zoom',
      icon: 'zoom',
      // icon: 'search-plus',
      //
      type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
      commandName: 'setToolActive',
      commandOptions: { toolName: 'Zoom' },
    },
    {
      id: 'Wwwc',
      label: 'Levels',
      icon: 'level',
      //
      type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
      commandName: 'setToolActive',
      commandOptions: { toolName: 'Wwwc' },
    },
    {
      id: 'Pan',
      label: 'Pan',
      // icon: 'arrows',
      icon: 'Hand',
      //
      type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
      commandName: 'setToolActive',
      commandOptions: { toolName: 'Pan' },
    },
    {
      // id: 'More',
      // label: 'Annotate',
      // icon: 'measure-non-target',
      // icon: 'More',
      // options: {
      //   behavior: TOOLBAR_BUTTON_BEHAVIORS.ARROW_ANNOTATE,
      // },
      //
      // type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
      // commandName: 'setToolActive',
      // commandOptions: { toolName: 'ArrowAnnotate' },
      buttons: [
        {
          id: 'ArrowAnnotate',
          label: 'Annotate',
          // icon: 'measure-non-target',
          icon: 'Anotate',
          //
          type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
          commandName: 'setToolActive',
          commandOptions: { toolName: 'ArrowAnnotate' },
        },
        /* {
          id: 'Undo',
          label: 'Undo',
          icon: 'undo',
          //
          type: TOOLBAR_BUTTON_TYPES.COMMAND,
          commandName: 'undoAnnotation',
        },
        {
          id: 'Redo',
          label: 'Redo',
          icon: 'redo',
          //
          type: TOOLBAR_BUTTON_TYPES.COMMAND,
          commandName: 'redoAnnotation',
        }, */
        {
          id: 'Length',
          label: 'Length',
          icon: 'scale',
          //
          type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
          commandName: 'setToolActive',
          commandOptions: { toolName: 'Length' },
        },

        {
          id: 'Angle',
          label: 'Angle',
          icon: 'measure-temp',
          //
          type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
          commandName: 'setToolActive',
          commandOptions: { toolName: 'Angle' },
        },

        {
          id: 'CobbAngle',
          label: 'CobbAngle',
          icon: 'cobbAngle',
          //
          type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
          commandName: 'setToolActive',
          commandOptions: { toolName: 'CobbAngle' },
        },
        {
          id: 'CTRRatioTool',
          label: 'CTRRatioTool',
          icon: 'cardio',
          //
          type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
          commandName: 'setToolActive',
          commandOptions: { toolName: 'CTRRatioTool' },
        },
        {
          id: 'Spine',
          label: 'Spine Labeling',
          icon: 'spine',
          //
          type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
          commandName: 'showSpineLabelingModal',
          commandOptions: {
            title: 'Spine Labeling',
          },
        },
        {
          id: 'EllipticalRoi',
          label: 'Ellipse',
          icon: 'ellipse',
          //
          type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
          commandName: 'setToolActive',
          commandOptions: { toolName: 'EllipticalRoi' },
        },
        {
          id: 'RectangleRoi',
          label: 'Rectangle',
          icon: 'rectangular',
          //
          type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
          commandName: 'setToolActive',
          commandOptions: { toolName: 'RectangleRoi' },
        },
        {
          id: 'FreehandRoi',
          label: 'Freehand',
          icon: 'freehand',
          //
          type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
          commandName: 'setToolActive',
          commandOptions: { toolName: 'FreehandRoi' },
        },
        {
          id: 'Bidirectional',
          label: 'Bidirectional',
          icon: 'transfer',
          //
          type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
          commandName: 'setToolActive',
          commandOptions: { toolName: 'Bidirectional' },
        },
      ],
    },
    {
      id: 'More',
      label: 'Segmentation',
      // icon: 'ellipse-circle',
      icon: 'marker',
      buttons: [
        {
          id: 'Brush',
          label: 'Brush',
          icon: 'brush',
          //
          type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
          commandName: 'setToolActive',
          commandOptions: { toolName: 'Brush' },
        },
        {
          id: 'CircleScissors',
          label: 'CircleScissors',
          icon: 'circle',
          //
          type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
          commandName: 'setToolActive',
          commandOptions: { toolName: 'CircleScissors' },
        },
        {
          id: 'FreehandScissors',
          label: 'FreehandScissors',
          icon: 'freehand',
          //
          type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
          commandName: 'setToolActive',
          commandOptions: { toolName: 'FreehandScissors' },
        },
        {
          id: 'RectangleScissors',
          label: 'RectangleScissors',
          icon: 'rectangular',
          //
          type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
          commandName: 'setToolActive',
          commandOptions: { toolName: 'RectangleScissors' },
        },
        {
          id: 'BrushEraser',
          label: 'Eraser',
          icon: 'trash',
          //
          type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
          commandName: 'setToolActive',
          commandOptions: { toolName: 'BrushEraser' },
        },
      ],
    },
    {
      id: 'Reset',
      label: 'Reset',
      icon: 'reset',
      //
      type: TOOLBAR_BUTTON_TYPES.COMMAND,
      commandName: 'resetViewport',
    },
    {
      id: 'Cine',
      label: 'Cine',
      // icon: 'youtube',
      icon: 'Camera',
      //
      type: TOOLBAR_BUTTON_TYPES.BUILT_IN,
      options: {
        behavior: TOOLBAR_BUTTON_BEHAVIORS.CINE,
      },
    },
    {
      id: 'More',
      label: 'More',
      // icon: 'ellipse-circle',
      icon: 'More',
      buttons: [
        {
          id: 'Magnify',
          label: 'Magnify',
          icon: 'magnify',
          //
          type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
          commandName: 'setToolActive',
          commandOptions: { toolName: 'Magnify' },
        },
        {
          id: 'WwwcRegion',
          label: 'ROI Window',
          icon: 'window',
          //
          type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
          commandName: 'setToolActive',
          commandOptions: { toolName: 'WwwcRegion' },
        },
        {
          id: 'DragProbe',
          label: 'Probe',
          icon: 'dental-probe',
          //
          type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
          commandName: 'setToolActive',
          commandOptions: { toolName: 'DragProbe' },
        },

        // {
        //   id: 'EllipticalRoi',
        //   label: 'Ellipse',
        //   icon: 'ellipse',
        //   //
        //   type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        //   commandName: 'setToolActive',
        //   commandOptions: { toolName: 'EllipticalRoi' },
        // },
        // {
        //   id: 'RectangleRoi',
        //   label: 'Rectangle',
        //   icon: 'rectangular',
        //   //
        //   type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        //   commandName: 'setToolActive',
        //   commandOptions: { toolName: 'RectangleRoi' },
        // },
        {
          id: 'Invert',
          label: 'Invert',
          icon: 'invert',
          //
          type: TOOLBAR_BUTTON_TYPES.COMMAND,
          commandName: 'invertViewport',
        },
        {
          id: 'Rotate',
          label: 'Rotate',
          icon: 'rotate-r',
          //
          type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
          commandName: 'setToolActive',
          commandOptions: { toolName: 'Rotate' },
        },
        {
          id: 'FlipH',
          label: 'Flip H',
          icon: 'flip-v',
          //
          type: TOOLBAR_BUTTON_TYPES.COMMAND,
          commandName: 'flipViewportHorizontal',
        },
        {
          id: 'FlipV',
          label: 'Flip V',
          icon: 'flip-h',
          //
          type: TOOLBAR_BUTTON_TYPES.COMMAND,
          commandName: 'flipViewportVertical',
        },
        // {
        //   id: 'Clear',
        //   label: 'Clear',
        //   icon: 'clear',
        //   //
        //   type: TOOLBAR_BUTTON_TYPES.COMMAND,
        //   commandName: 'clearAnnotations',
        // },
        // {
        //   id: 'Bidirectional',
        //   label: 'Bidirectional',
        //   icon: 'transfer',
        //   //
        //   type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        //   commandName: 'setToolActive',
        //   commandOptions: { toolName: 'Bidirectional' },
        // },
        {
          id: 'Download',
          label: 'Key Image',
          icon: 'image',
          //
          type: TOOLBAR_BUTTON_TYPES.BUILT_IN,
          options: {
            behavior: TOOLBAR_BUTTON_BEHAVIORS.DOWNLOAD_SCREEN_SHOT,
            togglable: true,
          },
        },
      ],
    },
    {
      id: 'Color',
      label: 'ColorLUT',
      icon: 'color-lut',
      buttons: [
        {
          id: 'gray',
          label: 'Gray',
          icon: 'xray',
          type: TOOLBAR_BUTTON_TYPES.COMMAND,
          commandName: 'applyColorLUTgray',
        },
        {
          id: 'hotiron',
          label: 'Hot Iron',
          icon: 'xray',
          type: TOOLBAR_BUTTON_TYPES.COMMAND,
          commandName: 'applyColorLUThotiron',
        },
        {
          id: 'pet',
          label: 'Pet',
          icon: 'xray',
          type: TOOLBAR_BUTTON_TYPES.COMMAND,
          commandName: 'applyColorLUTpet',
        },
        {
          id: 'hotmetalblue',
          label: 'Hot Metal Blue',
          icon: 'xray',
          type: TOOLBAR_BUTTON_TYPES.COMMAND,
          commandName: 'applyColorLUThotmetalblue',
        },
        /* {
          id: 'pet20step',
          label: 'Pet 20 Step',
          //
          icon: 'xray',
          type: TOOLBAR_BUTTON_TYPES.COMMAND,
          commandName: 'applyColorLUTpet20step',
        }, */
        {
          id: 'jet',
          label: 'Jet',
          icon: 'xray',
          //
          type: TOOLBAR_BUTTON_TYPES.COMMAND,
          commandName: 'applyColorLUTjet',
        },
        {
          id: 'hsv',
          label: 'HSV',
          icon: 'xray',
          //
          type: TOOLBAR_BUTTON_TYPES.COMMAND,
          commandName: 'applyColorLUThsv',
        },
        {
          id: 'hot',
          label: 'Hot',
          icon: 'xray',
          //
          type: TOOLBAR_BUTTON_TYPES.COMMAND,
          commandName: 'applyColorLUThot',
        },
        {
          id: 'cool',
          label: 'Cool',
          icon: 'xray',
          //
          type: TOOLBAR_BUTTON_TYPES.COMMAND,
          commandName: 'applyColorLUTcool',
        },

        {
          id: 'bone',
          label: 'Bone',
          icon: 'xray',
          //
          type: TOOLBAR_BUTTON_TYPES.COMMAND,
          commandName: 'applyColorLUTbone',
        },
        {
          id: 'copper',
          label: 'Copper',
          icon: 'xray',
          //
          type: TOOLBAR_BUTTON_TYPES.COMMAND,
          commandName: 'applyColorLUTcopper',
        },
        {
          id: 'spectral',
          label: 'Spectral',
          icon: 'xray',
          //
          type: TOOLBAR_BUTTON_TYPES.COMMAND,
          commandName: 'applyColorLUTspectral',
        },
        {
          id: 'coolwarm',
          label: 'Cool Warm',
          icon: 'xray',
          //
          type: TOOLBAR_BUTTON_TYPES.COMMAND,
          commandName: 'applyColorLUTcoolwarm',
        },
        {
          id: 'blues',
          label: 'Blues',
          icon: 'xray',
          //
          type: TOOLBAR_BUTTON_TYPES.COMMAND,
          commandName: 'applyColorLUTblues',
        },
      ],
    },
    // {
    //   id: 'Clear',
    //   label: 'Clear Image Annotations',
    //   icon: 'clearall',
    //   //
    //   type: TOOLBAR_BUTTON_TYPES.COMMAND,
    //   commandName: 'clearAll',
    // },
    // {
    //   id: 'WindowLevel',
    //   label: 'WindowLevel',
    //   icon: 'gray-scale',
    //   buttons: [
    //     {
    //       id: 'softtissue',
    //       label: 'Soft tissue',
    //       icon: 'soft-tissue',
    //       type: TOOLBAR_BUTTON_TYPES.COMMAND,
    //       commandName: 'windowLevelPreset1',
    //     },
    //     {
    //       id: 'lung',
    //       label: 'Lung',
    //       icon: 'lung',
    //       type: TOOLBAR_BUTTON_TYPES.COMMAND,
    //       commandName: 'windowLevelPreset2',
    //     },
    //     {
    //       id: 'liver',
    //       label: 'Liver',
    //       icon: 'liver',
    //       type: TOOLBAR_BUTTON_TYPES.COMMAND,
    //       commandName: 'windowLevelPreset3',
    //     },
    //     {
    //       id: 'bone',
    //       label: 'Bone',
    //       icon: 'bone',
    //       type: TOOLBAR_BUTTON_TYPES.COMMAND,
    //       commandName: 'windowLevelPreset4',
    //     },
    //     {
    //       id: 'brain',
    //       label: 'Brain',
    //       icon: 'brain',
    //       type: TOOLBAR_BUTTON_TYPES.COMMAND,
    //       commandName: 'windowLevelPreset5',
    //     },
    //     {
    //       id: 'trest',
    //       label: 'Trest',
    //       icon: 'gray-scale',
    //       type: TOOLBAR_BUTTON_TYPES.COMMAND,
    //       commandName: 'windowLevelPreset6',
    //     },
    //   ],
    // },

    // {
    //   id: 'Exit2DMPR',
    //   label: 'Exit 2D MPR',
    //   icon: 'times',
    //   //
    //   type: TOOLBAR_BUTTON_TYPES.COMMAND,
    //   commandName: 'setCornerstoneLayout',
    //   context: 'ACTIVE_VIEWPORT::VTK',
    // },
  ];

  return buttons;
};

const definitions = codetest();

export default {
  definitions,
  defaultContext: 'ACTIVE_VIEWPORT::CORNERSTONE',
};
