import React from 'react';
import PropTypes from 'prop-types';
import { ErrorBoundary } from 'react-error-boundary';
import './ErrorFallback.css';
import { LoginApi } from '../../../../../Constants/baseURL';
import cogoToast from 'cogo-toast';

const ReportBug = async (error, componentStack) => {
  const url = `${LoginApi}/api/v1/emailsend/sendEmailLink`;

  const emailBody = `
    A BUG HAS BEEN REPORTED

    ====================================

    ERROR DETAILS:
    ====================================
    Reason: ${error}

    ====================================

    COMPONENT STACK:
    ====================================
    ${componentStack}

    ====================================

    Please investigate this issue.
  `;

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        address: 'nixaamteammern@gmail.com',
        link: emailBody,
        subject: 'BUG REPORT FOR DCMCLOUD',
      }),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status} - ${response.statusText}`);
    }

    cogoToast.success('Bug report sent successfully', {
      position: 'bottom-right',
    });
  } catch (err) {
    cogoToast.error('Failed to send bug report', { position: 'bottom-right' });
  }
};

const ErrorFallback = ({ error, componentStack, resetErrorBoundary }) => {
  return (
    <div
      className="ErrorFallback flex flex-col items-center justify-center"
      role="alert"
    >
      <div
        className="flex h-screen justify-center items-center flex-col"
        style={{ width: '80%' }}
      >
        <div className="bg-gray-800 p-5 w-full flex items-center justify-center rounded-t-lg shadow-lg">
          <span className="text-white text-lg font-semibold">
            Oops! Something went wrong, please either report the bug, go to
            home, or log out.
          </span>
        </div>

        <div className="bg-black h-60 w-full overflow-y-auto p-4 border-2 border-gray-800 shadow-lg">
          <div className="text-red-600 font-semibold text-lg">
            Reason: {error.message}
          </div>
          <div className="text-white mt-1 whitespace-pre-wrap">
            {componentStack}
          </div>
        </div>

        <div className="w-full flex items-center justify-between space-x-4 bg-gray-800 p-5  rounded-b-lg shadow-lg">
          <button
            onClick={() => {
              localStorage.removeItem('userData');
              localStorage.removeItem('token');
              window.location.href = '/';
            }}
            className="bg-red-500 text-white py-2 px-4 rounded-lg hover:bg-red-600 transition-colors duration-200"
          >
            Log Out
          </button>
          <div className="flex space-x-4">
            <button
              onClick={() => {
                window.location.href = '/';
              }}
              className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition-colors duration-200"
            >
              Go to Home
            </button>
            <button
              onClick={() => ReportBug(error.message, componentStack)}
              className="bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-green-600 transition-colors duration-200"
            >
              Report Bug
            </button>
          </div>
        </div>
      </div>

      {/* <p>Something went wrong.</p>
      <pre>{error.message}</pre>
      <pre>{componentStack}</pre> */}
    </div>
  );
};

const DCMCloudErrorBoundary = ({
  context = 'DCMCloud',
  onReset = () => { },
  onError = () => { },
  fallbackComponent,
  children,
}) => {
  const onErrorHandler = (error, componentStack) => {
    console.error(`${context} Error Boundary`, error, componentStack);
    onError(error, componentStack);
  };

  const onResetHandler = () => {
    onReset();
  };

  return (
    <ErrorBoundary
      FallbackComponent={fallbackComponent || ErrorFallback}
      onReset={onResetHandler}
      onError={onErrorHandler}
    >
      {children}
    </ErrorBoundary>
  );
};

DCMCloudErrorBoundary.propTypes = {
  context: PropTypes.string,
  onReset: PropTypes.func,
  onError: PropTypes.func,
  children: PropTypes.node.isRequired,
  fallbackComponent: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.element,
  ]),
};

export default DCMCloudErrorBoundary;
