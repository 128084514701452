import { importInternal, getToolState, toolColors, addToolState } from 'cornerstone-tools';

const draw = importInternal('drawing/draw');
const drawLine = importInternal('drawing/drawLine');
const drawLinkedTextBox = importInternal('drawing/drawLinkedTextBox');
const drawHandles = importInternal('drawing/drawHandles');
const getNewContext = importInternal('drawing/getNewContext');
const BaseAnnotationTool = importInternal('base/BaseAnnotationTool');

const getPixelSpacing = importInternal('util/getPixelSpacing');
const throttle = importInternal('util/throttle');

class CTRRatioTool extends BaseAnnotationTool {
  constructor(props = {}) {
    const cursors = cornerstoneTools.importInternal('tools/cursors');
    const cursor = cursors.arrowAnnotateCursor; // `MouseCursor` class
    const defaultProps = {
      name: 'CTRRatioTool',
      supportedInteractionTypes: ['Mouse', 'Touch'],
      configuration: {
        drawHandles: true,
        drawHandlesOnHover: false,
        hideHandlesIfMoving: false,
        renderDashed: false,
      },
      svgCursor: cursor,
    };

    super(props, defaultProps);

    this.throttledUpdateCachedStats = throttle(this.updateCachedStats, 110);
  }

  createNewMeasurement(eventData) {
    return {
      visible: true,
      active: true,
      color: undefined,
      invalidated: true,
      complete: false,
      handles: {
        start: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: false,
        },
        end: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: true,
        },
        textBox: {
          active: false,
          hasMoved: false,
          movesIndependently: false,
          drawnIndependently: true,
          allowedOutsideImage: true,
          hasBoundingBox: true,
        },
      },
    };
  }

  pointNearTool(element, data, coords) {
    const { start, end } = data.handles;
    const distance = this.distanceFromLine(element, start, end, coords);
    return distance < 5;
  }

  distanceFromLine(element, start, end, coords) {
    const { rowPixelSpacing, columnPixelSpacing } = getPixelSpacing(element);

    const dx = (end.x - start.x) * (columnPixelSpacing || 1);
    const dy = (end.y - start.y) * (rowPixelSpacing || 1);
    const length = Math.sqrt(dx * dx + dy * dy);

    if (length === 0) {
      return Math.sqrt(
        Math.pow((coords.x - start.x) * (columnPixelSpacing || 1), 2) +
        Math.pow((coords.y - start.y) * (rowPixelSpacing || 1), 2)
      );
    }

    const t = ((coords.x - start.x) * dx + (coords.y - start.y) * dy) / (length * length);
    const closestX = start.x + t * dx / (columnPixelSpacing || 1);
    const closestY = start.y + t * dy / (rowPixelSpacing || 1);

    return Math.sqrt(
      Math.pow((coords.x - closestX) * (columnPixelSpacing || 1), 2) +
      Math.pow((coords.y - closestY) * (rowPixelSpacing || 1), 2)
    );
  }

  updateCachedStats(image, element, data) {
    const { rowPixelSpacing, columnPixelSpacing } = getPixelSpacing(image);

    const length = this.calculateLength(data.handles, rowPixelSpacing, columnPixelSpacing);

    data.length = length;
    data.invalidated = false;
  }

  renderToolData(evt) {
    const eventData = evt.detail;
    const { element, canvasContext } = eventData;
    const toolData = this.getToolState(element);

    if (!toolData) {
      return;
    }

    const context = getNewContext(canvasContext.canvas);

    // Draw the CTR ratio lines and measurements
    toolData.data.forEach((data, index) => {
      this.drawCTRRatio(context, element, data, index, toolData.data, eventData);
    });
  }

  drawCTRRatio(context, element, data, index, allData, eventData) {
    const { configuration } = this;
    const color = cornerstoneTools.toolColors.getToolColor();
    const lineWidth = cornerstoneTools.toolStyle.getActiveWidth();

    const { start, end } = data.handles;

    // Draw the line
    draw(context, (context) => {
      drawLine(context, element, start, end, { color });
    });

    // Draw the handles
    const handleOptions = {
      color,
      handleRadius: 6,
      drawHandlesIfActive: configuration.drawHandlesOnHover,
      hideHandlesIfMoving: configuration.hideHandlesIfMoving,
    };

    if (configuration.drawHandles) {
      drawHandles(context, eventData, data.handles, handleOptions);
    }

    // Draw the text
    if (data.length) {
      const text = `${(data.length / 10).toFixed(2)} cm`;
      const textCoords = {
        x: (start.x + end.x) / 2,
        y: (start.y + end.y) / 2,
      };

      const textBoxAnchorPoints = () => [start, end];

      /* drawLinkedTextBox(
        context,
        element,
        data.handles.textBox,
        text,
        textCoords,
        textBoxAnchorPoints,
        color,
        lineWidth,
        0,
        true
      ); */
    }

    // Draw CTR ratio if this is the second line
    if (index % 2 === 1) {
      const prevData = allData[index - 1];
      const length1 = prevData.length;
      const length2 = data.length;
      const ratio = ((length1 / length2) * 100).toFixed(2) + '%';

      const ratioCoords = {
        x: (prevData.handles.end.x + data.handles.start.x) / 2,
        y: (prevData.handles.end.y + data.handles.start.y) / 2,
      };

      const ratioTextBoxAnchorPoints = () => [prevData.handles.end, data.handles.start];

      drawLinkedTextBox(
        context,
        element,
        data.handles.textBox,
        `CTR Ratio: ${ratio}`,
        ratioCoords,
        ratioTextBoxAnchorPoints,
        '#27ff00',
        lineWidth,
        0,
        true
      );
    }
  }

  calculateLength(handles, rowPixelSpacing, columnPixelSpacing) {
    const { start, end } = handles;
    const dx = (start.x - end.x) * (columnPixelSpacing || 1);
    const dy = (start.y - end.y) * (rowPixelSpacing || 1);

    return Math.sqrt(dx * dx + dy * dy);
  }

  // Add methods for tool interaction (e.g., mouse down, touch start, etc.) here

  activeCallback(element) {
    this.onImageRendered = this.onImageRendered.bind(this);
    element.addEventListener('cornerstoneimagerendered', this.onImageRendered);
  }

  onImageRendered(evt) {
    const eventData = evt.detail;
    this.renderToolData(evt);
  }

  // Implement the getToolState method
  getToolState(element) {
    const toolState = cornerstoneTools.getToolState(element, this.name);
    return toolState;
  }
}

export default CTRRatioTool;
