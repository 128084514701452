import cornerstone from 'cornerstone-core';
import cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader';
import { api } from 'dicomweb-client';
import DICOMWeb from '../DICOMWeb';
import errorHandler from '../errorHandler';
import getXHRRetryRequestHook from './xhrRetryRequestHook';
import { MeasurementApi, TimepointApi } from '../measurements/classes';
import store from '../../../viewer/src/store/index.js';

import { setTimepoints, setMeasurements } from '../redux/actions';

const onTimepointsUpdated = timepoints => {
  store.dispatch(setTimepoints(timepoints));
};

const onMeasurementsUpdated = measurements => {
  store.dispatch(setMeasurements(measurements));
};

const getImageId = imageObj => {
  if (!imageObj) {
    return;
  }

  return typeof imageObj.getImageId === 'function'
    ? imageObj.getImageId()
    : imageObj.url;
};

const findImageIdOnStudies = (studies, displaySetInstanceUID) => {
  const study = studies.find(study => {
    const displaySet = study.displaySets.some(
      displaySet => displaySet.displaySetInstanceUID === displaySetInstanceUID
    );
    return displaySet;
  });
  const { series = [] } = study;
  const { instances = [] } = series[0] || {};
  const instance = instances[0];

  return getImageId(instance);
};

const someInvalidStrings = strings => {
  const stringsArray = Array.isArray(strings) ? strings : [strings];
  const emptyString = string => !string;
  let invalid = stringsArray.some(emptyString);
  return invalid;
};

const getImageInstance = dataset => {
  return dataset && dataset.images && dataset.images[0];
};

const getImageInstanceId = imageInstance => {
  return getImageId(imageInstance);
};

const fetchIt = (url, headers = DICOMWeb.getAuthorizationHeader()) => {
  return fetch(url, headers).then(response => response.arrayBuffer());
};
// const token = localStorage.getItem('token');
// const fetchIt = (url, headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }) => {
//   return fetch(url, { headers }).then(response => response.arrayBuffer());
// };

const cornerstoneRetriever = imageId => {
  return cornerstone.loadAndCacheImage(imageId).then(image => {
    return image && image.data && image.data.byteArray.buffer;
  });
};

const wadorsRetriever = (
  url,
  studyInstanceUID,
  seriesInstanceUID,
  sopInstanceUID,
  headers = DICOMWeb.getAuthorizationHeader(),
  errorInterceptor = errorHandler.getHTTPErrorHandler()
) => {
  // const config = {
  //   url,
  //   headers,
  //   errorInterceptor,
  //   requestHooks: [getXHRRetryRequestHook()],
  // };

  const searchParams = new URLSearchParams(window.location.search);
  const path = searchParams.get('Path');

  // https://rad-test.icenna.com

  const url1 =
    path != null ? 'https://rad-test.icenna.com' : 'https://demo.dcmcloud.com';

  const config = {
    url: url1,
    headers,
    errorInterceptor,
    requestHooks: [getXHRRetryRequestHook()],
  };
  const dicomWeb = new api.DICOMwebClient(config);

  const currentTimepointId = 'TimepointId';

  const timepointApi = new TimepointApi(currentTimepointId, {
    onTimepointsUpdated,
  });

  const currentDate = new Date().toISOString();

  timepointApi.timepoints = [
    {
      timepointType: 'baseline',
      timepointId: currentTimepointId,
      studyInstanceUIDs: [studyInstanceUID],
      PatientID: undefined,
      earliestDate: currentDate,
      latestDate: '1000-01-01T00:00:00.000Z',
      isLocked: false,
    },
  ];

  const measurementApiInstance = new MeasurementApi(timepointApi, {
    onMeasurementsUpdated,
  });

  const prevToolGroups = JSON.parse(localStorage.getItem('prevToolGroups'));

  prevToolGroups.allTools.forEach(tool => {
    measurementApiInstance.deleteMeasurements(
      tool.toolId,
      'allTools',
      {
        lesionNamingNumber: tool.lesionNamingNumber,
        timepointId: tool.timepointId,
      },
      true
    );
  });

  measurementApiInstance.retrieveMeasurements(
    'None',
    currentTimepointId,
    seriesInstanceUID
  );

  //const BASE64 = window.location.href.split('%5E')[1];
  const BASE64 = getBase64FromUrl();

  const queryParameters = {
    base64: BASE64,
    region: 'us-east-1',
    patient_name: path != null ? path : '',
  };

  return dicomWeb.retrieveInstance({
    studyInstanceUID,
    seriesInstanceUID,
    sopInstanceUID,
    queryParameters,
  });
};

function getBase64FromUrl() {
  try {
    // Helper function to clean and extract base64 part
    const extractBase64Part = str => {
      if (!str) return null;

      // Handle different URL formats
      if (str.includes('%5E')) {
        const parts = str.split('%5E');
        if (parts.length > 1) {
          // Remove any additional parameters after the base64
          return parts[1].split('&')[0] || parts[1].split('#')[0] || parts[1];
        }
      } else if (str.includes('^')) {
        const parts = str.split('^');
        if (parts.length > 1) {
          return parts[1].split('&')[0] || parts[1].split('#')[0] || parts[1];
        }
      }
      return null;
    };

    // Try multiple approaches to get the URL
    const approaches = [
      // Approach 1: Using window.location.pathname
      () => {
        const pathParts = window.location.pathname.split('/');
        const viewerIndex = pathParts.indexOf('viewer');
        if (viewerIndex !== -1 && viewerIndex + 1 < pathParts.length) {
          const fullPath = pathParts.slice(viewerIndex + 1).join('/');
          return extractBase64Part(fullPath);
        }
        return null;
      },

      // Approach 2: Using window.location.href
      () => {
        return extractBase64Part(window.location.href);
      },

      // Approach 3: Using window.location.search
      () => {
        const searchParams = new URLSearchParams(window.location.search);
        const base64Value = searchParams.get('base64');
        return base64Value || null;
      },

      // Approach 4: Using document.URL as fallback
      () => {
        return extractBase64Part(document.URL);
      },
    ];

    // Try each approach until we get a valid result
    for (const approach of approaches) {
      const result = approach();
      if (result) {
        return result;
      }
    }

    // If we get here, log a detailed error message
    console.warn('Base64 value not found in URL. Current location:', {
      href: window.location.href,
      pathname: window.location.pathname,
      search: window.location.search,
      hash: window.location.hash,
    });

    return null;
  } catch (error) {
    console.error('Error extracting base64 from URL:', error, {
      location: window.location.href,
    });
    return null;
  }
}

const getImageLoaderType = imageId => {
  const loaderRegExp = /^\w+\:/;
  const loaderType = loaderRegExp.exec(imageId);

  return (
    (loaderRegExp.lastIndex === 0 &&
      loaderType &&
      loaderType[0] &&
      loaderType[0].replace(':', '')) ||
    ''
  );
};

class DicomLoaderService {
  getLocalData(dataset, studies) {
    if (dataset && dataset.localFile) {
      // Use referenced imageInstance
      const imageInstance = getImageInstance(dataset);
      let imageId = getImageInstanceId(imageInstance);

      // or Try to get it from studies
      if (someInvalidStrings(imageId)) {
        imageId = findImageIdOnStudies(studies, dataset.displaySetInstanceUID);
      }

      if (!someInvalidStrings(imageId)) {
        return cornerstoneWADOImageLoader.wadouri.loadFileRequest(imageId);
      }
    }
  }

  getDataByImageType(dataset) {
    const imageInstance = getImageInstance(dataset);

    if (imageInstance) {
      const imageId = getImageInstanceId(imageInstance);
      let getDicomDataMethod = fetchIt;
      const loaderType = getImageLoaderType(imageId);

      switch (loaderType) {
        case 'dicomfile':
          getDicomDataMethod = cornerstoneRetriever.bind(this, imageId);
          break;
        case 'wadors':
          const url = imageInstance.getData().wadoRoot;
          const studyInstanceUID = imageInstance.getStudyInstanceUID();
          const seriesInstanceUID = imageInstance.getSeriesInstanceUID();
          const sopInstanceUID = imageInstance.getSOPInstanceUID();
          const invalidParams = someInvalidStrings([
            url,
            studyInstanceUID,
            seriesInstanceUID,
            sopInstanceUID,
          ]);
          if (invalidParams) {
            return;
          }
          // debugger;
          getDicomDataMethod = wadorsRetriever.bind(
            this,
            url,
            studyInstanceUID,
            seriesInstanceUID,
            sopInstanceUID
          );
          break;
        case 'wadouri':
          // Strip out the image loader specifier
          imageId = imageId.substring(imageId.indexOf(':') + 1);

          if (someInvalidStrings(imageId)) {
            return;
          }
          getDicomDataMethod = fetchIt.bind(this, imageId);
          break;
      }

      return getDicomDataMethod();
    }
  }

  getDataByDatasetType(dataset) {
    const {
      StudyInstanceUID,
      SeriesInstanceUID,
      SOPInstanceUID,
      authorizationHeaders,
      wadoRoot,
      wadoUri,
    } = dataset;
    // Retrieve wadors or just try to fetch wadouri
    if (!someInvalidStrings(wadoRoot)) {
      return wadorsRetriever(
        wadoRoot,
        StudyInstanceUID,
        SeriesInstanceUID,
        SOPInstanceUID,
        authorizationHeaders
      );
    } else if (!someInvalidStrings(wadoUri)) {
      return fetchIt(wadoUri, { headers: authorizationHeaders });
    }
  }

  *getLoaderIterator(dataset, studies) {
    yield this.getLocalData(dataset, studies);
    yield this.getDataByImageType(dataset);
    yield this.getDataByDatasetType(dataset);
  }

  findDicomDataPromise(dataset, studies) {
    const loaderIterator = this.getLoaderIterator(dataset, studies);
    // it returns first valid retriever method.
    for (const loader of loaderIterator) {
      if (loader) {
        return loader;
      }
    }

    // in case of no valid loader
    throw new Error('Invalid dicom data loader');
  }
}

const dicomLoaderService = new DicomLoaderService();

export default dicomLoaderService;
