import lib from 'query-string';

const PARAM_SEPARATOR = ';';
const PARAM_PATTERN_IDENTIFIER = ':';

function toLowerCaseFirstLetter(word) {
  return word[0].toLowerCase() + word.slice(1);
}
const getQueryFilters = (location = {}) => {
  const { search } = location;

  if (!search) {
    return;
  }

  const searchParameters = parse(search);
  const filters = {};

  Object.entries(searchParameters).forEach(([key, value]) => {
    filters[toLowerCaseFirstLetter(key)] = value;
  });

  return filters;
};

const decode = (strToDecode = '') => {
  if (!strToDecode) return '';

  try {
    // First try URL decoding
    return decodeURIComponent(strToDecode);
  } catch (e) {
    // If URL decoding fails, try direct string manipulation
    return strToDecode.replace(/[+]/g, ' ');
  }
};

const parse = toParse => {
  if (toParse) {
    return lib.parse(toParse);
  }

  return {};
};
const parseParam = paramStr => {
  if (!paramStr) return [];

  // Directly split the string without decoding
  return paramStr.split(PARAM_SEPARATOR).map(segment => {
    // Remove any whitespace
    segment = segment.trim();

    // Try to convert to number if it's purely numeric
    if (/^\d+$/.test(segment)) {
      return Number(segment);
    }
    // For mixed or alphabetic content, keep as string
    return segment;
  });
};

const replaceParam = (path = '', paramKey, paramValue) => {
  const paramPattern = `${PARAM_PATTERN_IDENTIFIER}${paramKey}`;
  if (paramValue) {
    return path.replace(paramPattern, paramValue);
  }

  return path;
};

const isValidPath = path => {
  const paramPatternPiece = `/${PARAM_PATTERN_IDENTIFIER}`;
  return path.indexOf(paramPatternPiece) < 0;
};

const queryString = {
  getQueryFilters,
};

const paramString = {
  isValidPath,
  parseParam,
  replaceParam,
};

export { parse, queryString, paramString };
