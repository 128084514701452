import '../viewportDownloadForm/ViewportDownloadForm.css';
import '../viewportDownloadForm/ViewportDownloadForm.styl';

import React, { useState } from 'react';
import './SpineLabelingModal.css';

import AppContext from '../../../../../viewer/src/context/AppContext';
import PropTypes from 'prop-types';

const SpineLabelingModal = ({ onClose, onStartLabeling }) => {

  const {
    selectedLabel,
    setSelectedLabel,
    direction,
    setDirection,
    excludedLabels,
    setExcludedLabels,
    isExcludeMode,
    setIsExcludeMode
  } = React.useContext(AppContext);

  const labels = [
    ['C1', 'C2', 'C3', 'C4', 'C5', 'C6', 'C7'],
    ['T1', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7', 'T8', 'T9', 'T10', 'T11', 'T12'],
    ['L1', 'L2', 'L3', 'L4', 'L5'],
    ['S1', 'S2', 'S3', 'S4', 'S5']
  ];

  const handleLabelClick = (label) => {
    if (isExcludeMode) {
      setExcludedLabels(prev =>
        prev.includes(label) ? prev.filter(l => l !== label) : [...prev, label]
      );
    } else {
      setSelectedLabel(label);
    }
  };

  const handleStartLabeling = () => {
    onStartLabeling({
      selectedLabel,
      direction,
      excludedLabels
    });
  };

  return (
    <div className="ViewportDownloadForm ViewportDownloadFormMain">
      <div className="label-selection">
        <p>Select the label to start from: <em style={{ color: 'red' }}>*</em> </p>
        <div className="label-grid">
          {labels.map((group, groupIndex) => (
            <div key={groupIndex} className="label-group">
              {group.map(label => (
                <button
                  key={label}
                  className={`label-button ${selectedLabel === label ? 'selected' : ''} ${excludedLabels.includes(label) ? 'excluded' : ''}`}
                  onClick={() => handleLabelClick(label)}
                >
                  {label}
                </button>
              ))}
            </div>
          ))}
        </div>
      </div>
      <div className="direction-selection">
        <p>Set direction of labeling:</p>
        <div className="direction-buttons">
          <button
            className={`direction-button ${direction === 'ascend' ? 'selected' : ''}`}
            onClick={() => setDirection('ascend')}
          >
            Ascend
          </button>
          <button
            className={`direction-button ${direction === 'descend' ? 'selected' : ''}`}
            onClick={() => setDirection('descend')}
          >
            Descend
          </button>
        </div>
      </div>
      <div className="exclude-labels">
        <p>Mark the labels to be excluded:</p>
        <button
          className={`exclude-button ${isExcludeMode ? 'active' : ''}`}
          onClick={() => setIsExcludeMode(!isExcludeMode)}
        >
          Exclude Labels
        </button>
      </div>
      <div className="modal-actions">
        <button className="start-button" onClick={handleStartLabeling}>
          Start Labeling
        </button>
        <button className="close-button" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};

SpineLabelingModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onStartLabeling: PropTypes.func.isRequired,
};

export default SpineLabelingModal;
