import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import classNames from 'classnames';

import './DCMCloudModal.styl';

const customStyle = {
  overlay: {
    zIndex: 1071,
    backgroundColor: 'rgb(0, 0, 0, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

Modal.setAppElement(document.getElementById('root'));

const DCMCloudModal = ({
  className,
  closeButton,
  shouldCloseOnEsc,
  isOpen,
  fullscreen,
  title,
  onClose,
  children,
}) => {
  const renderHeader = () => {
    return (
      title && (
        <div
          // localStorage.getItem('dcmCloudTheme') === null
          className={
            localStorage.getItem('dcmCloudTheme') === null ||
            localStorage.getItem('dcmCloudTheme') === 'white'
              ? 'DCMCloudModal__header '
              : 'DCMCloudModal__header BB'
          }
          style={
            localStorage.getItem('dcmCloudTheme') === null ||
            localStorage.getItem('dcmCloudTheme') === 'white'
              ? {}
              : { border: '1px solid white' }
          }
          data-cy="modal-header"
        >
          <h4 style={{ color: 'white' }}>{title}</h4>
          {closeButton && (
            <button
              data-cy="close-button"
              style={{ color: 'white' }}
              onClick={onClose}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="white"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          )}
        </div>
      )
    );
  };

  const classes = fullscreen
    ? classNames('DCMCloudModal', className, 'DCMCloudModal-fullscreen')
    : classNames('DCMCloudModal', className);

  return (
    <Modal
      className={classes}
      data-cy="modal"
      shouldCloseOnEsc={shouldCloseOnEsc}
      isOpen={isOpen}
      title={title}
      style={customStyle}
    >
      <>
        {renderHeader()}
        <div
          // className="DCMCloudModal__content BB"
          className={
            localStorage.getItem('dcmCloudTheme') === null ||
            localStorage.getItem('dcmCloudTheme') === 'white'
              ? 'DCMCloudModal__content'
              : 'DCMCloudModal__content BB'
          }
          style={
            localStorage.getItem('dcmCloudTheme') === null ||
            localStorage.getItem('dcmCloudTheme') === 'white'
              ? {}
              : { border: '1px solid white' }
          }
          data-cy="modal-content"
        >
          {children}
        </div>
      </>
    </Modal>
  );
};

DCMCloudModal.propTypes = {
  className: PropTypes.string,
  closeButton: PropTypes.bool,
  shouldCloseOnEsc: PropTypes.bool,
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  onClose: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default DCMCloudModal;
