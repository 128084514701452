import dcmjs from 'dcmjs';
import { api } from 'dicomweb-client';

import DICOMWeb from '../DICOMWeb';
import parseDicomStructuredReport from './parseDicomStructuredReport';
import parseMeasurementsData from './parseMeasurementsData';
import getAllDisplaySets from './utils/getAllDisplaySets';
import errorHandler from '../errorHandler';
import getXHRRetryRequestHook from '../utils/xhrRetryRequestHook';

import { servicesManager } from '../../../viewer/src/App.js';

const VERSION_NAME = 'dcmjs-0.0';
const TRANSFER_SYNTAX_UID = '1.2.840.10008.1.2.1';

/**
 * Function to retrieve measurements from DICOM Structured Reports coming from determined server
 *
 * @param {Array} series - List of all series metaData loaded
 * @param {Array} studies - List of all studies metaData loaded
 * @param {string} serverUrl - Server URL to be used on request
 * @param {object} external
 * @returns {Object} MeasurementData
 */
const retrieveMeasurementFromSR = async (
  series,
  studies,
  serverUrl,
  external
) => {
  // const config = {
  //   url: serverUrl,
  //   headers: DICOMWeb.getAuthorizationHeader(),
  //   errorInterceptor: errorHandler.getHTTPErrorHandler(),
  //   requestHooks: [getXHRRetryRequestHook()],
  // };

  const token = localStorage.getItem('token');
  // if (!token) {
  //   localStorage.removeItem('token');
  //   localStorage.removeItem('userData');
  //   window.location.href = '/';
  // }

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const searchParams = new URLSearchParams(window.location.search);
  const path = searchParams.get('Path');

  console.log(DICOMWeb.getAuthorizationHeader());

  // https://rad-test.icenna.com

  const config = {
    url:
      path != null
        ? 'https://rad-test.icenna.com'
        : 'https://demo.dcmcloud.com',
    headers: headers, //DICOMWeb.getAuthorizationHeader(),
    errorInterceptor: errorHandler.getHTTPErrorHandler(),
    requestHooks: [getXHRRetryRequestHook()],
  };

  const dicomWeb = new api.DICOMwebClient(config);
  const instance = series.getFirstInstance();

  //const BASE64 = window.location.href.split('%5E')[1];
  const BASE64 = getBase64FromUrl();

  let parsed;
  const user_data = localStorage.getItem('userData');
  if (user_data != 'undefined' && user_data != 'null') {
    parsed = JSON.parse(user_data);
  }

  const patientName = searchParams.get('PatientName');

  const queryParameters = {
    base64: path != null ? path : BASE64,
    region: 'us-east-1',
    patient_name: parsed
      ? parsed.privateServer == false
        ? path
        : patientName
      : path,
  };

  const options = {
    studyInstanceUID: instance.getStudyInstanceUID(),
    seriesInstanceUID: instance.getSeriesInstanceUID(),
    sopInstanceUID: instance.getSOPInstanceUID(),
    queryParameters: queryParameters,
  };

  if (localStorage.getItem('once') == 0) {
    external.servicesManager = servicesManager;
    localStorage.setItem('once', 1);
  }

  const part10SRArrayBuffer = await dicomWeb.retrieveInstance(options);
  const displaySets = getAllDisplaySets(studies);
  const measurementsData = parseDicomStructuredReport(
    part10SRArrayBuffer,
    displaySets,
    external
  );

  const dataset = dcmjs.data.DicomMessage.readFile(part10SRArrayBuffer);

  const dicomDictionary = dcmjs.data.DicomMetaDictionary.namifyDataset(
    dataset.dict
  );

  updateMeasurementsData(dicomDictionary, measurementsData);

  for (const key in measurementsData) {
    if (measurementsData.hasOwnProperty(key)) {
      const dataArray = measurementsData[key];
      if (Array.isArray(dataArray) && dataArray.length > 0) {
        const locationDescriptionPairs = dataArray.map(item => ({
          location: item.location,
          description: item.description,
        }));

        locationDescriptionPairs.reverse();

        dataArray.forEach((item, index) => {
          item.location = locationDescriptionPairs[index].location;
          item.description = locationDescriptionPairs[index].description;
        });
      }
    }
  }
  return measurementsData;
};

/**
 * Function to store measurements to DICOM Structured Reports in determined server
 *
 * @param {Object} measurements - DCMCloud measurementData object
 * @param {string} serverUrl - Server URL to be used on request
 * @returns {Promise}
 */
const stowSRFromMeasurements = async (measurements, serverUrl) => {
  const { dataset } = parseMeasurementsData(measurements);
  let findings;

  const newtexts = measurements['allTools'].map(measurement => {
    const location = measurement.location || '...';
    const description = measurement.description || '...';
    return `${location} (${description})`;
  });

  const base64 = getBase64FromUrl();
  const StudyInstanceUID = window.location.href
    .split('/viewer/')[1]
    .split('%5E')[0];

  // Fetch findings from API
  const url = `https://rad-test.icenna.com/get_findings2?base64=${base64}&StudyInstanceUID=${StudyInstanceUID}&region=us-east-1`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });

  const data = await response.json();

  if (data.status === 'success') {
    // Save SR with both measurements and findings
    findings = data.description;
    appendFindingsToSR(dataset, findings);
  }

  appendTextToLengthTool(dataset, newtexts);

  const { DicomMetaDictionary, DicomDict } = dcmjs.data;
  const meta = {
    FileMetaInformationVersion: dataset._meta.FileMetaInformationVersion.Value,
    MediaStorageSOPClassUID: dataset.SOPClassUID,
    MediaStorageSOPInstanceUID: dataset.SOPInstanceUID,
    TransferSyntaxUID: TRANSFER_SYNTAX_UID,
    ImplementationClassUID: DicomMetaDictionary.uid(),
    ImplementationVersionName: VERSION_NAME,
  };

  const denaturalized = DicomMetaDictionary.denaturalizeDataset(meta);
  const dicomDict = new DicomDict(denaturalized);

  dicomDict.dict = DicomMetaDictionary.denaturalizeDataset(dataset);

  const part10Buffer = dicomDict.write();

  // const config = {
  //   url: serverUrl,
  //   headers: DICOMWeb.getAuthorizationHeader(),
  //   errorInterceptor: errorHandler.getHTTPErrorHandler(),
  //   requestHooks: [getXHRRetryRequestHook()],
  // };

  const token = localStorage.getItem('token');

  // if (!token) {
  //   localStorage.removeItem('token');
  //   localStorage.removeItem('userData');
  //   window.location.href = '/';
  // }

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const searchParams = new URLSearchParams(window.location.search);
  const path = searchParams.get('Path');

  let parsed;
  const user_data = localStorage.getItem('userData');
  if (user_data != 'undefined' && user_data != 'null') {
    parsed = JSON.parse(user_data);
  }

  const patientName = searchParams.get('PatientName');

  // https://rad-test.icenna.com

  const config = {
    url:
      path != null
        ? 'https://rad-test.icenna.com'
        : 'https://demo.dcmcloud.com',
    headers: headers, //DICOMWeb.getAuthorizationHeader(),
    errorInterceptor: errorHandler.getHTTPErrorHandler(),
    requestHooks: [getXHRRetryRequestHook()],
  };

  //const BASE64 = window.location.href.split('%5E')[1];
  const BASE64 = getBase64FromUrl();

  const queryParameters = {
    base64: BASE64,
    region: 'us-east-1',
    patient_name: parsed
      ? parsed.privateServer == false
        ? path
        : patientName
      : path,
  };

  const dicomWeb = new api.DICOMwebClient(config);
  const options = {
    datasets: [part10Buffer],
    queryParameters: queryParameters,
  };

  await dicomWeb.storeInstances(options);
};

function getBase64FromUrl() {
  try {
    // Helper function to clean and extract base64 part
    const extractBase64Part = str => {
      if (!str) return null;

      // Handle different URL formats
      if (str.includes('%5E')) {
        const parts = str.split('%5E');
        if (parts.length > 1) {
          // Remove any additional parameters after the base64
          return parts[1].split('&')[0] || parts[1].split('#')[0] || parts[1];
        }
      } else if (str.includes('^')) {
        const parts = str.split('^');
        if (parts.length > 1) {
          return parts[1].split('&')[0] || parts[1].split('#')[0] || parts[1];
        }
      }
      return null;
    };

    // Try multiple approaches to get the URL
    const approaches = [
      // Approach 1: Using window.location.pathname
      () => {
        const pathParts = window.location.pathname.split('/');
        const viewerIndex = pathParts.indexOf('viewer');
        if (viewerIndex !== -1 && viewerIndex + 1 < pathParts.length) {
          const fullPath = pathParts.slice(viewerIndex + 1).join('/');
          return extractBase64Part(fullPath);
        }
        return null;
      },

      // Approach 2: Using window.location.href
      () => {
        return extractBase64Part(window.location.href);
      },

      // Approach 3: Using window.location.search
      () => {
        const searchParams = new URLSearchParams(window.location.search);
        const base64Value = searchParams.get('base64');
        return base64Value || null;
      },

      // Approach 4: Using document.URL as fallback
      () => {
        return extractBase64Part(document.URL);
      },
    ];

    // Try each approach until we get a valid result
    for (const approach of approaches) {
      const result = approach();
      if (result) {
        return result;
      }
    }

    // If we get here, log a detailed error message
    console.warn('Base64 value not found in URL. Current location:', {
      href: window.location.href,
      pathname: window.location.pathname,
      search: window.location.search,
      hash: window.location.hash,
    });

    return null;
  } catch (error) {
    console.error('Error extracting base64 from URL:', error, {
      location: window.location.href,
    });
    return null;
  }
}

const appendTextToLengthTool = (dataset, text) => {
  dataset.ContentSequence.forEach(contentItem => {
    if (
      contentItem.ConceptNameCodeSequence.CodeMeaning === 'Imaging Measurements'
    ) {
      let i = 0;
      contentItem.ContentSequence.forEach(measurementGroup => {
        if (!Array.isArray(measurementGroup.ContentSequence)) {
          measurementGroup.ContentSequence = [];
        }

        measurementGroup.ContentSequence.push({
          RelationshipType: 'CONTAINS',
          ValueType: 'TEXT',
          ConceptNameCodeSequence: [
            {
              CodeValue: 'CORNERSTONEFREETEXT',
              CodingSchemeDesignator: 'CST4',
              CodeMeaning: 'Label/Description',
            },
          ],
          TextValue: text[i],
        });

        i++;
      });
    }
  });
};

const appendFindingsToSR = (dataset, findings) => {
  // Helper function to create a TEXT content item

  console.log(findings, 'findings');

  const createTextContentItem = (text, conceptName) => ({
    RelationshipType: 'CONTAINS',
    ValueType: 'TEXT',
    ConceptNameCodeSequence: [
      {
        CodeValue: conceptName.code || 'CORNERSTONEFREETEXT',
        CodingSchemeDesignator: conceptName.scheme || 'CST4',
        CodeMeaning: conceptName.meaning || 'Findings Description',
      },
    ],
    TextValue: text,
  });

  // Helper function to create a CONTAINER content item
  const createContainerContentItem = conceptName => ({
    RelationshipType: 'CONTAINS',
    ValueType: 'CONTAINER',
    ConceptNameCodeSequence: [
      {
        CodeValue: conceptName.code || '121070',
        CodingSchemeDesignator: conceptName.scheme || 'DCM',
        CodeMeaning: conceptName.meaning || 'Findings',
      },
    ],
    ContinuityOfContent: 'SEPARATE',
    ContentSequence: [],
  });

  // Process HTML content to plain text
  const convertHtmlToPlainText = htmlString => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlString;
    return tempDiv.textContent || tempDiv.innerText || '';
  };

  // Find or create the main content sequence
  if (!dataset.ContentSequence) {
    dataset.ContentSequence = [];
  }

  // Look for existing Findings container
  let findingsContainer = dataset.ContentSequence.find(
    contentItem =>
      contentItem.ConceptNameCodeSequence.CodeMeaning === 'Findings'
  );

  // If no findings container exists, create one
  if (!findingsContainer) {
    findingsContainer = createContainerContentItem({
      meaning: 'Findings',
      code: '121070',
      scheme: 'DCM',
    });
    dataset.ContentSequence.push(findingsContainer);
  }

  // Convert HTML findings to plain text
  const plainTextFindings = convertHtmlToPlainText(findings);

  // Create findings text content item
  const findingsTextItem = createTextContentItem(plainTextFindings, {
    meaning: 'Findings Description',
    code: 'FINDINGSTEXT',
    scheme: 'CST4',
  });

  // Add findings to the container
  if (!findingsContainer.ContentSequence) {
    findingsContainer.ContentSequence = [];
  }
  findingsContainer.ContentSequence.push(findingsTextItem);
};

const updateMeasurementsData = (dataset, measurementsData) => {
  let newnumber = 0;
  for (const key in measurementsData) {
    if (measurementsData.hasOwnProperty(key)) {
      const dataArray = measurementsData[key];
      if (Array.isArray(dataArray) && dataArray.length > 0) {
        dataArray.forEach(item => {
          if (item.measurementNumber > newnumber) {
            newnumber = item.measurementNumber;
          }
        });
      }
    }
  }
  dataset.ContentSequence.Value.forEach(contentItem => {
    if (
      contentItem.ConceptNameCodeSequence.Value[0].CodeMeaning.Value[0] ===
      'Imaging Measurements'
    ) {
      contentItem.ContentSequence.Value.forEach(measurementGroup => {
        if (!Array.isArray(measurementGroup.ContentSequence.Value)) {
          measurementGroup.ContentSequence.Value = [];
        }

        measurementGroup.ContentSequence.Value.forEach(item => {
          if (
            item.ConceptNameCodeSequence.Value[0].CodeMeaning.Value[0] ===
            'Label/Description'
          ) {
            for (const key in measurementsData) {
              if (measurementsData.hasOwnProperty(key)) {
                const dataArray = measurementsData[key];
                if (Array.isArray(dataArray) && dataArray.length > 0) {
                  dataArray.forEach(newItem => {
                    if (newItem.measurementNumber == newnumber) {
                      let value = item.TextValue.Value[0];
                      let parts = value.split('(');
                      newItem.location = parts[0].trim();
                      newItem.description = parts[1]
                        ? parts[1].replace(')', '').trim()
                        : '';
                      newnumber--;
                    }
                  });
                }
              }
            }
          }
        });
      });
    }
  });
};

export { retrieveMeasurementFromSR, stowSRFromMeasurements };
