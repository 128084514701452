import React from 'react';
import { Component } from 'react';
import { View3D } from 'react-vtkjs-viewport';
import vtkVolume from 'vtk.js/Sources/Rendering/Core/Volume';
import vtkVolumeMapper from 'vtk.js/Sources/Rendering/Core/VolumeMapper';
// import './initCornerstone.js';
import vtkColorTransferFunction from 'vtk.js/Sources/Rendering/Core/ColorTransferFunction';
import vtkPiecewiseFunction from 'vtk.js/Sources/Common/DataModel/PiecewiseFunction';
import vtkColorMaps from 'vtk.js/Sources/Rendering/Core/ColorTransferFunction/ColorMaps';
import vtkInteractorStyleTrackballCamera from 'vtk.js/Sources/Interaction/Style/InteractorStyleTrackballCamera';
import vtkCellPicker from 'vtk.js/Sources/Rendering/Core/CellPicker';
import vtkPointPicker from 'vtk.js/Sources/Rendering/Core/PointPicker';
import vtkImageData from 'vtk.js/Sources/Common/DataModel/ImageData';
//import { connect } from 'react-redux';
import presets from './presets.js';
import PropTypes from 'prop-types';
import './Render.css';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import '@kitware/vtk.js/Rendering/Profiles/Geometry';
import '@kitware/vtk.js/Rendering/Profiles/Glyph';

import DeepEqual from 'deep-equal';
import vtkActor from '@kitware/vtk.js/Rendering/Core/Actor';
import vtkCubeSource from '@kitware/vtk.js/Filters/Sources/CubeSource';
import vtkFullScreenRenderWindow from '@kitware/vtk.js/Rendering/Misc/FullScreenRenderWindow';
import vtkMapper from '@kitware/vtk.js/Rendering/Core/Mapper';
import vtkLineWidget from '@kitware/vtk.js/Widgets/Widgets3D/LineWidget';
import vtkWidgetManager from '@kitware/vtk.js/Widgets/Core/WidgetManager';
import vtkInteractorObserver from '@kitware/vtk.js/Rendering/Core/InteractorObserver';
import vtkOpenGLRenderWindow from '@kitware/vtk.js/Rendering/OpenGL/RenderWindow.js';
import vtkRenderWindow from '@kitware/vtk.js/Rendering/Core/RenderWindow.js';
import vtkRenderer from '@kitware/vtk.js/Rendering/OpenGL/Renderer.js';
import vtkPolyLine from '@kitware/vtk.js/Common/DataModel/PolyLine.js';
import vtkLine from '@kitware/vtk.js/Common/DataModel/Line.js';
import vtkLineWidget$1 from '@kitware/vtk.js/Widgets/Widgets3D/LineWidget';

function createActorMapper(imageData) {
  const mapper = vtkVolumeMapper.newInstance();
  mapper.setInputData(imageData);

  const actor = vtkVolume.newInstance();
  actor.setMapper(mapper);

  return {
    actor,
    mapper,
  };
}

function getShiftRange(colorTransferArray) {
  // Credit to paraview-glance
  // https://github.com/Kitware/paraview-glance/blob/3fec8eeff31e9c19ad5b6bff8e7159bd745e2ba9/src/components/controls/ColorBy/script.js#L133

  // shift range is original rgb/opacity range centered around 0
  let min = Infinity;
  let max = -Infinity;
  for (let i = 0; i < colorTransferArray.length; i += 4) {
    min = Math.min(min, colorTransferArray[i]);
    max = Math.max(max, colorTransferArray[i]);
  }

  const center = (max - min) / 2;

  return {
    shiftRange: [-center, center],
    min,
    max,
  };
}

function applyPointsToPiecewiseFunction(points, range, pwf) {
  const width = range[1] - range[0];
  const rescaled = points.map(([x, y]) => [x * width + range[0], y]);

  pwf.removeAllPoints();
  rescaled.forEach(([x, y]) => pwf.addPoint(x, y));

  return rescaled;
}

function applyPointsToRGBFunction(points, range, cfun) {
  const width = range[1] - range[0];
  const rescaled = points.map(([x, r, g, b]) => [
    x * width + range[0],
    r,
    g,
    b,
  ]);

  cfun.removeAllPoints();
  rescaled.forEach(([x, r, g, b]) => cfun.addRGBPoint(x, r, g, b));

  return rescaled;
}

function applyPreset(actor, preset) {
  // Create color transfer function
  const colorTransferArray = preset.colorTransfer
    .split(' ')
    .splice(1)
    .map(parseFloat);

  const { shiftRange } = getShiftRange(colorTransferArray);
  let min = shiftRange[0];
  const width = shiftRange[1] - shiftRange[0];
  const cfun = vtkColorTransferFunction.newInstance();
  const normColorTransferValuePoints = [];
  for (let i = 0; i < colorTransferArray.length; i += 4) {
    let value = colorTransferArray[i];
    const r = colorTransferArray[i + 1];
    const g = colorTransferArray[i + 2];
    const b = colorTransferArray[i + 3];

    value = (value - min) / width;
    normColorTransferValuePoints.push([value, r, g, b]);
  }

  applyPointsToRGBFunction(normColorTransferValuePoints, shiftRange, cfun);

  actor.getProperty().setRGBTransferFunction(0, cfun);

  // Create scalar opacity function
  const scalarOpacityArray = preset.scalarOpacity
    .split(' ')
    .splice(1)
    .map(parseFloat);

  const ofun = vtkPiecewiseFunction.newInstance();
  const normPoints = [];
  for (let i = 0; i < scalarOpacityArray.length; i += 2) {
    let value = scalarOpacityArray[i];
    const opacity = scalarOpacityArray[i + 1];

    value = (value - min) / width;

    normPoints.push([value, opacity]);
  }

  applyPointsToPiecewiseFunction(normPoints, shiftRange, ofun);

  actor.getProperty().setScalarOpacity(0, ofun);

  const [
    gradientMinValue,
    gradientMinOpacity,
    gradientMaxValue,
    gradientMaxOpacity,
  ] = preset.gradientOpacity
    .split(' ')
    .splice(1)
    .map(parseFloat);

  actor.getProperty().setUseGradientOpacity(0, true);
  actor.getProperty().setGradientOpacityMinimumValue(0, gradientMinValue);
  actor.getProperty().setGradientOpacityMinimumOpacity(0, gradientMinOpacity);
  actor.getProperty().setGradientOpacityMaximumValue(0, gradientMaxValue);
  actor.getProperty().setGradientOpacityMaximumOpacity(0, gradientMaxOpacity);

  if (preset.interpolation === '1') {
    actor.getProperty().setInterpolationTypeToFastLinear();
    //actor.getProperty().setInterpolationTypeToLinear()
  }

  const ambient = parseFloat(preset.ambient);
  //const shade = preset.shade === '1'
  const diffuse = parseFloat(preset.diffuse);
  const specular = parseFloat(preset.specular);
  const specularPower = parseFloat(preset.specularPower);

  //actor.getProperty().setShade(shade)
  actor.getProperty().setAmbient(ambient);
  actor.getProperty().setDiffuse(diffuse);
  actor.getProperty().setSpecular(specular);
  actor.getProperty().setSpecularPower(specularPower);
}

function createCT3dPipeline(imageData, ctTransferFunctionPresetId) {
  const { actor, mapper } = createActorMapper(imageData);

  const sampleDistance =
    1.2 *
    Math.sqrt(
      imageData
        .getSpacing()
        .map(v => v * v)
        .reduce((a, b) => a + b, 0)
    );

  const range = imageData
    .getPointData()
    .getScalars()
    .getRange();
  actor
    .getProperty()
    .getRGBTransferFunction(0)
    .setRange(range[0], range[1]);

  mapper.setSampleDistance(sampleDistance);

  const preset = presets.find(
    preset => preset.id === ctTransferFunctionPresetId
  );

  applyPreset(actor, preset);

  actor.getProperty().setScalarOpacityUnitDistance(0, 2.5);

  return actor;
}

function calculateRenderedVolumePercentage(imageData, threshold = 0) {
  const dims = imageData.getDimensions();
  const scalars = imageData.getPointData().getScalars();
  let totalVoxels = dims[0] * dims[1] * dims[2];
  let activeVoxels = 0;

  // Check if scalars is available
  if (!scalars || !scalars.getData) {
    console.error('Scalar data is not available or in an unexpected format');
    return 0;
  }

  const scalarData = scalars.getData();

  for (let i = 0; i < totalVoxels; i++) {
    if (scalarData[i] > threshold) {
      activeVoxels++;
    }
  }

  return (activeVoxels / totalVoxels) * 100;
}

class VTKFusionExample extends Component {
  state = {
    volumeRenderingVolumes: null,
    ctTransferFunctionPresetId: 'vtkMRMLVolumePropertyNode4',
    petColorMapId: 'hsv',
    volumePercentage: 0,
    isDropDown: false,
    hoveredIndex: null,
    isVolumeModal: false,
    lineWidget: null,
    selectedWidgetIndex: null,
    getHandle: {},
    svgCleanupCallbacks: [],
  };

  constructor(props) {
    super(props);
    this.view3DRef = React.createRef();
    this.picker = null;
    this.modalRef = React.createRef();
  }

  async componentDidMount() {
    // const imageIdPromise = createStudyImageIds(url, searchInstanceOptions);

    this.apis = [];

    const obj = JSON.parse(localStorage.getItem('imageDataObject'));

    /* const ctImageData = this.props.imageDataObject.vtkImageData;
    const ctVolVR = createCT3dPipeline(
      ctImageData,
      this.state.ctTransferFunctionPresetId
    );

    this.setState(
      {
        volumeRenderingVolumes: [ctVolVR],
        percentComplete: 0,
      },
      () => {
        this.initializePicker();
        this.setupInteraction();
      }
    ); */

    const ctImageData = this.props.imageDataObject.vtkImageData;
    const ctVolVR = createCT3dPipeline(
      ctImageData,
      this.state.ctTransferFunctionPresetId
    );

    let renderedPercentage = 0;
    try {
      renderedPercentage = calculateRenderedVolumePercentage(ctImageData);
    } catch (error) {
      console.error('Error calculating rendered volume percentage:', error);
    }

    // const renderedPercentage = calculateRenderedVolumePercentage(ctImageData);
    // console.log(renderedPercentage);

    this.setState({ volumePercentage: renderedPercentage.toFixed(2) });

    this.setState(
      {
        volumeRenderingVolumes: [ctVolVR],
        percentComplete: 0,
      },
      () => {
        this.initializePicker();
        this.setupInteraction();
      }
    );
  }

  initializePicker() {
    try {
      this.picker = vtkPointPicker.newInstance();
      this.picker.getRenderer(
        this.view3DRef.current.genericRenderWindow.getRenderer()[0]
      );
    } catch (error) {
      console.error('Error initializing picker:', error);
    }
  }

  setupInteraction() {
    if (this.view3DRef.current) {
      const renderWindow = this.view3DRef.current.genericRenderWindow.getRenderWindow();
      const renderer = renderWindow.getRenderers()[0];

      const interactor = renderWindow.getInteractor();
      interactor.onLeftButtonPress(callData => {
        const pos = callData.position;
        const xyz = this.pick3DPoint(renderer, pos.x, pos.y, pos);

        let renderedPercentage = 0;
        const ctImageData = this.props.imageDataObject.vtkImageData;
        if (xyz) {
          renderedPercentage = calculateRenderedVolumePercentage(ctImageData);

          const percentage = this.calculateVolumePercentageAtPoint(xyz);
          this.setState({ volumePercentage: renderedPercentage.toFixed(2) });
        } else {
          console.log('No point picked');
        }
      });
    } else {
      console.error('view3DRef is not available');
    }
  }

  pick3DPoint = (renderer, x, y) => {
    if (!this.picker || !renderer) {
      return null;
    }

    try {
      this.picker.setPickFromList(true);
      this.picker.initializePickList();

      if (
        this.state.volumeRenderingVolumes &&
        this.state.volumeRenderingVolumes[0]
      ) {
        this.picker.addPickList(this.state.volumeRenderingVolumes[0]);
      } else {
        return null;
      }

      const mapperPosition = this.picker.getMapperPosition();
      if (mapperPosition) {
        return mapperPosition;
      } else {
        return null;
      }
    } catch (error) {
      console.error('Error during picking:', error);
      return null;
    }
  };

  calculateVolumePercentageAtPoint = point => {
    if (
      !this.state.volumeRenderingVolumes ||
      this.state.volumeRenderingVolumes.length === 0
    ) {
      return 0;
    }

    const volume = this.state.volumeRenderingVolumes[0];
    const bounds = volume.getBounds();
    const xSize = bounds[1] - bounds[0];
    const ySize = bounds[3] - bounds[2];
    const zSize = bounds[5] - bounds[4];

    const totalVolume = xSize * ySize * zSize;
    const center = [
      (bounds[1] + bounds[0]) / 2,
      (bounds[3] + bounds[2]) / 2,
      (bounds[5] + bounds[4]) / 2,
    ];

    const distance = Math.sqrt(
      Math.pow(point[0] - center[0], 2) +
        Math.pow(point[1] - center[1], 2) +
        Math.pow(point[2] - center[2], 2)
    );

    const maxDistance = Math.sqrt(
      Math.pow(xSize / 2, 2) + Math.pow(ySize / 2, 2) + Math.pow(zSize / 2, 2)
    );

    let volumeRatio = 1 - distance / maxDistance;
    if (volumeRatio < 0) volumeRatio = 0;

    return volumeRatio * 100;
  };

  saveApiReference = api => {
    this.apis = [api];
  };

  handleMouseEnter = index => {
    this.setState({ hoveredIndex: index });
  };

  handleMouseLeave = () => {
    this.setState({ hoveredIndex: null });
  };

  handleClickOutside = event => {
    if (
      this.modalRef.current &&
      !this.modalRef.current.contains(event.target)
    ) {
      this.setState({ isDropDown: false });
    }
  };

  toggleDropDown = () => {
    this.setState(prevState => ({ isDropDown: !prevState.isDropDown }));
  };

  dropToggle = e => {
    e.stopPropagation();
    this.setState(prevState => ({
      isDropDown: !prevState.isDropDown,
    }));
  };

  openModal = e => {
    e.stopPropagation();
    this.setState(
      prevState => ({ isVolumeModal: !prevState.isVolumeModal }),
      () => {
        if (this.state.isVolumeModal) {
          this.setupInteraction();
        }
      }
    );
  };

  handleChangeCTTransferFunction = optionid => {
    const ctTransferFunctionPresetId = optionid; //event.target.value;
    const preset = presets.find(
      preset => preset.id === ctTransferFunctionPresetId
    );

    const actor = this.state.volumeRenderingVolumes[0];

    applyPreset(actor, preset);

    this.rerenderAll();

    this.setState({
      ctTransferFunctionPresetId,
    });
  };

  rerenderAll = () => {
    Object.keys(this.apis).forEach(viewportIndex => {
      const renderWindow = this.apis[
        viewportIndex
      ].genericRenderWindow.getRenderWindow();

      renderWindow.render();
    });
  };

  // handles are small but line is large

  /* addWidget = () => {
    console.log(this.apis[0]);
    const renderer1 = this.apis[0].genericRenderWindow.getRenderer();
    console.log(renderer1);
    const mapper = vtkMapper.newInstance();
    const actor = vtkActor.newInstance({
      scale: [0.1, 0.1, 0.1],
    });

    //check with other apis[0] renderer

    actor.setMapper(mapper);
    renderer1.addActor(actor);

    console.log(renderer1);

    console.log(this.state.widget);

    // Release focus from the previous widget if any
    this.apis[0].widgetManager.releaseFocus(this.state.widget);

    const widget = vtkLineWidget.newInstance();
    //mapper.setInputConnection(widget.getOutputPort());
    const currentHandle = this.apis[0].widgetManager.addWidget(widget);
    const actor1 = this.state.volumeRenderingVolumes[0];
    const bounds = actor1.getBounds();

    // // Calculate the center of actor1's bounds
    // const centerX = (bounds[0] + bounds[1]) / 2;
    // const centerY = (bounds[2] + bounds[3]) / 2;
    // const centerZ = (bounds[4] + bounds[5]) / 2;

    // // Set actor's position to match the center of actor1's bounds
    // actor.setPosition(centerX, centerY, centerZ);

    // // Optionally, if you need to scale actor to fit within the bounds of actor1
    // const scaleX = bounds[1] - bounds[0];
    // const scaleY = bounds[3] - bounds[2];
    // const scaleZ = bounds[5] - bounds[4];

    // // You can scale the actor to fit within the bounds of actor1
    // actor.setScale(scaleX - 10000, scaleY - 10000, scaleZ - 10000);

    // console.log(scaleX);
    // console.log(scaleY);
    // console.log(scaleZ);

    //const bounds2 = actor.getBounds();

    //console.log(bounds);
    //console.log(bounds2);

    const widgetState = currentHandle.getWidgetState();
    const handle1 = widgetState.getHandle1();
    const handle2 = widgetState.getHandle2();
    // handle1.setScale1(0.6);
    // handle2.setScale1(0.6);

    // Update the state
    this.setState({
      widget,
    });

    this.apis[0].widgetManager.grabFocus(widget);

    currentHandle.onStartInteractionEvent(() => { });
    currentHandle.onInteractionEvent(() => { });
    currentHandle.onEndInteractionEvent(() => {
      console.log(widget.getDistance().toFixed(2));
      handle1.setScale1(0.2);
      handle2.setScale1(0.2);
    });
  }; */

  // handles are large but line is small
  /* addWidget = () => {
    const renderer = this.apis[0].genericRenderWindow.getRenderer();
    const renderWindow = renderer.getRenderWindow();

    // Release focus from the previous widget if any
    this.apis[0].widgetManager.releaseFocus(this.state.widget);

    const widget = vtkLineWidget.newInstance();
    const currentHandle = this.apis[0].widgetManager.addWidget(widget);
    const actor1 = this.state.volumeRenderingVolumes[0];
    const bounds = actor1.getBounds();

    // Calculate the diagonal length of the bounding box
    const diagonalLength = Math.sqrt(
      Math.pow(bounds[1] - bounds[0], 2) +
      Math.pow(bounds[3] - bounds[2], 2) +
      Math.pow(bounds[5] - bounds[4], 2)
    );

    const widgetState = currentHandle.getWidgetState();
    const handle1 = widgetState.getHandle1();
    const handle2 = widgetState.getHandle2();

    // Set handle scales (adjust the multiplier as needed)
    const handleScale = diagonalLength * 0.01;
    handle1.setScale1(handleScale);
    handle2.setScale1(handleScale);

    // Position the handles
    const center = [
      (bounds[0] + bounds[1]) / 2,
      (bounds[2] + bounds[3]) / 2,
      (bounds[4] + bounds[5]) / 2
    ];

    handle1.setOrigin(
      center[0] - diagonalLength * 0.1,
      center[1],
      center[2]
    );
    handle2.setOrigin(
      center[0] + diagonalLength * 0.1,
      center[1],
      center[2]
    );

    // Set line appearance
    const lineActor = widget.getLineActor();
    if (lineActor) {
      lineActor.getProperty().setLineWidth(2); // Adjust this value to change line thickness
      lineActor.getProperty().setColor(1, 1, 1); // Set line color (white in this case)
    }

    // Update the state
    this.setState({
      widget,
    });

    this.apis[0].widgetManager.grabFocus(widget);

    currentHandle.onStartInteractionEvent(() => { });
    currentHandle.onInteractionEvent(() => {
      this.updateDistance();
    });
    currentHandle.onEndInteractionEvent(() => {
      this.updateDistance();
    });

    // Force a render to show the widget
    renderWindow.render();
  };

  updateDistance = () => {
    if (this.state.widget) {
      const distance = this.state.widget.getDistance();
      console.log(`Distance: ${distance.toFixed(2)}`);
      // You can update state or UI with this distance if needed
    }
  }; */

  addLineWidget = () => {
    const renderer = this.apis[0].genericRenderWindow.getRenderer();
    const widgetManager = this.apis[0].widgetManager;

    // Release focus from the previous widget if any
    widgetManager.releaseFocus(this.state.widget);

    const lineWidget = vtkLineWidget.newInstance();
    const currentHandle = widgetManager.addWidget(lineWidget);

    // Set the handle size
    const handleSize = 0.1;

    // Set the active handle

    const widgetState = currentHandle.getWidgetState();
    const handle1 = widgetState.getHandle1();
    const handle2 = widgetState.getHandle2();

    // Update the state
    this.setState({
      widget: lineWidget,
    });

    widgetManager.grabFocus(lineWidget);

    currentHandle.onStartInteractionEvent(() => {
      console.log('Start interaction event');
    });

    currentHandle.onInteractionEvent(() => {
      console.log('Interaction event');
    });

    currentHandle.onEndInteractionEvent(() => {
      console.log('End interaction event');

      const distance = lineWidget.getDistance();
      console.log(`Distance: ${distance.toFixed(2)}`);
    });
  };

  render() {
    if (!this.state.volumeRenderingVolumes) {
      return <h4>Loading...</h4>;
    }
    const hoverStyle = {
      backgroundColor: '#393939',
      transition: 'backgroundColor 0.3s ease-in-out',
    };

    const hoverEffect = {
      backgroundColor: '#545454',
      transition: 'backgroundColor 0.3s ease-in-out',
    };

    const isCT = localStorage.getItem('ModalityCT');
    const isMR = localStorage.getItem('ModalityMR');

    let ctTransferFunctionPresetOptions = presets.map(preset => ({
      id: preset.id,
      name: preset.name,
    }));

    if (isCT) {
      ctTransferFunctionPresetOptions = presets
        .filter((preset, index) => index < 23)
        .map(preset => ({ id: preset.id, name: preset.name }));
    } else if (isMR) {
      ctTransferFunctionPresetOptions = presets
        .filter((preset, index) => index >= 23 && index < 28)
        .map(preset => ({ id: preset.id, name: preset.name }));
    }

    return (
      <>
        {/*<div className="col-xs-12">
          <div
            className="line-button toolbar-button"
            style={{
              position: 'absolute',
              top: '5px',
              right: '75px',
              zIndex: '1',
            }}
            onClick={this.addLineWidget}
          >
            <svg
              width="64"
              height="64"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              fill="#fff"
            >
              <g stroke-width="0" />
              <g stroke-linecap="round" stroke-linejoin="round" />
              <path d="M2 19v3h3v-2.293L19.707 5H22V2h-3v2.293L4.293 19zm2 2H3v-1h1zM20 3h1v1h-1z" />
              <path fill="none" d="M0 0h24v24H0z" />
            </svg>
            <div
              className="line-button-tooltip toolbar-button-label"
              style={{ left: '-50px' }}
            >
              Straight Line
            </div>
          </div>
        </div>*/}
        <div className="col-xs-12 mt-8 md:mt-14 lg:mt-5 xl:mt-0">
          <div
            className="volume-button toolbar-button"
            style={{
              position: 'absolute',
              top: '5px',
              right: '40px',
              zIndex: '1',
            }}
            onClick={this.openModal}
          >
            <svg
              fill="#ffffff"
              height="25px"
              width="25px"
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 507.6 507.6"
              stroke="#ffffff"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0" />
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <g id="SVGRepo_iconCarrier">
                <g>
                  <g>
                    <path d="M492.8,421.2c-2.8,0-6.4,0.8-8.4,2l-65.6-37.6V203.2c0,0,0.4-3.2,0-5.2c-0.4-1.2-1.2-1.6-1.2-1.6L264,105.2V46 c4-2.8,7.2-8,7.2-13.6c0-8.8-7.6-16-16.4-16c-8.8,0-15.6,7.2-15.6,16c0,6,1.2,11.2,8.8,14v58.8L93.6,195.6c-2,1.2-3.2,2-4.4,4 c-0.8,1.6-0.4,5.6-0.4,5.6v179.2L24,423.2c-2.4-1.2-5.2-2-8-2c-8.8,0-16,7.2-16,15.6c0,8.4,6.8,15.6,15.6,15.6 c8.8,0,15.6-7.2,15.6-15.6l63.2-38.4l154,90.8c1.6,1.2,3.2,2,5.2,2c1.2,0,2.8-0.4,4-1.2l154-90.8l64.8,38 c0,8.8,7.2,15.6,15.6,15.6c8.8,0,15.6-7.2,15.6-15.6C507.6,428.8,501.2,421.2,492.8,421.2z M247.6,123.6v158.964L112.8,203.2 L247.6,123.6z M264,123.6l134.8,79.6L264,282.8V123.6z M264,469.2V310.4l134,79.2L264,469.2z M402.8,376.4l-135.2-80l135.2-79.6 V376.4z" />{' '}
                  </g>
                </g>
              </g>
            </svg>
            <div
              className="volume-button-tooltip toolbar-button-label"
              style={{ left: '-50px' }}
            >
              Volume Percentage
            </div>
          </div>
        </div>
        <div className="col-xs-12 mt-8 md:mt-14 lg:mt-5 xl:mt-0">
          <div
            style={{
              position: 'absolute',
              top: '5px',
              right: '5px',
              zIndex: '1',
            }}
          >
            <div
              className="flex flex-col relative mr-[2px]"
              ref={this.modalRef}
              onClick={e => e.stopPropagation()}
            >
              <button
                className="toolbar-button compare"
                onClick={e => this.dropToggle(e)}
              >
                <svg
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <path
                      d="M20.058 9.72255C21.0065 9.18858 21.4808 8.9216 21.7404 8.49142C22 8.06124 22 7.54232 22 6.50448V5.81466C22 4.48782 22 3.8244 21.5607 3.4122C21.1213 3 20.4142 3 19 3H5C3.58579 3 2.87868 3 2.43934 3.4122C2 3.8244 2 4.48782 2 5.81466V6.50448C2 7.54232 2 8.06124 2.2596 8.49142C2.5192 8.9216 2.99347 9.18858 3.94202 9.72255L6.85504 11.3624C7.49146 11.7206 7.80967 11.8998 8.03751 12.0976C8.51199 12.5095 8.80408 12.9935 8.93644 13.5872C9 13.8722 9 14.2058 9 14.8729L9 17.5424C9 18.452 9 18.9067 9.25192 19.2613C9.50385 19.6158 9.95128 19.7907 10.8462 20.1406C12.7248 20.875 13.6641 21.2422 14.3321 20.8244C15 20.4066 15 19.4519 15 17.5424V14.8729C15 14.2058 15 13.8722 15.0636 13.5872C15.1959 12.9935 15.488 12.5095 15.9625 12.0976"
                      stroke="#ffffff"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    ></path>
                  </g>
                </svg>
                <div className="toolbar-button-label" style={{ left: '-20px' }}>
                  Rendering Preset
                </div>
              </button>
              {this.state.isDropDown && (
                <div className="absolute right-0 mt-10 z-50 w-60 max-h-80 overflow-y-auto bg-light-gray p-1 rounded-lg">
                  {ctTransferFunctionPresetOptions.map((option, index) => {
                    const style =
                      this.state.hoveredIndex === index
                        ? hoverEffect
                        : hoverStyle;

                    return (
                      <div
                        onMouseEnter={() => this.handleMouseEnter(index)}
                        onMouseLeave={this.handleMouseLeave}
                        style={style}
                        className="cursor-pointer p-2"
                        key={index}
                        onClick={() =>
                          this.handleChangeCTTransferFunction(option.id)
                        }
                      >
                        {option.name}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className="element3d"
          style={{
            height: '100%',
            width: '100%',
          }}
        >
          <View3D
            volumes={this.state.volumeRenderingVolumes}
            onCreated={this.saveApiReference}
            ref={this.view3DRef}
          />
          {this.state.isVolumeModal && (
            <>
              {' '}
              <div class="progress-bar">
                <div
                  class="progressUI"
                  style={{
                    height: `${this.state.volumePercentage}%`,
                  }}
                ></div>
              </div>
              <div
                style={{
                  position: 'absolute',
                  bottom: '10px',
                  left: '10px',
                  color: 'white',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  padding: '5px',
                  borderRadius: '5px',
                }}
              >
                {this.state.volumePercentage}%
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}

export default VTKFusionExample;
