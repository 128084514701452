import React, { useState, useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Modal from 'react-modal';
import './tab.css';
import { Icon } from '../../elements/Icon';

function getBase64FromUrl() {
  try {
    // Helper function to clean and extract base64 part
    const extractBase64Part = str => {
      if (!str) return null;

      // Handle different URL formats
      if (str.includes('%5E')) {
        const parts = str.split('%5E');
        if (parts.length > 1) {
          // Remove any additional parameters after the base64
          return parts[1].split('&')[0] || parts[1].split('#')[0] || parts[1];
        }
      } else if (str.includes('^')) {
        const parts = str.split('^');
        if (parts.length > 1) {
          return parts[1].split('&')[0] || parts[1].split('#')[0] || parts[1];
        }
      }
      return null;
    };

    // Try multiple approaches to get the URL
    const approaches = [
      // Approach 1: Using window.location.pathname
      () => {
        const pathParts = window.location.pathname.split('/');
        const viewerIndex = pathParts.indexOf('viewer');
        if (viewerIndex !== -1 && viewerIndex + 1 < pathParts.length) {
          const fullPath = pathParts.slice(viewerIndex + 1).join('/');
          return extractBase64Part(fullPath);
        }
        return null;
      },

      // Approach 2: Using window.location.href
      () => {
        return extractBase64Part(window.location.href);
      },

      // Approach 3: Using window.location.search
      () => {
        const searchParams = new URLSearchParams(window.location.search);
        const base64Value = searchParams.get('base64');
        return base64Value || null;
      },

      // Approach 4: Using document.URL as fallback
      () => {
        return extractBase64Part(document.URL);
      },
    ];

    // Try each approach until we get a valid result
    for (const approach of approaches) {
      const result = approach();
      if (result) {
        return result;
      }
    }

    // If we get here, log a detailed error message
    console.warn('Base64 value not found in URL. Current location:', {
      href: window.location.href,
      pathname: window.location.pathname,
      search: window.location.search,
      hash: window.location.hash,
    });

    return null;
  } catch (error) {
    console.error('Error extracting base64 from URL:', error, {
      location: window.location.href,
    });
    return null;
  }
}

const Findings = ({
  measurements,
  onItemClick,
  description,
  setDescription,
  setLoading,
  isLoading,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [countOpen, setCountOpen] = useState(false);
  const [editorContent, setEditorContent] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const [mentionIndex, setMentionIndex] = useState(-1);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const quillRef = useRef(null);

  useEffect(() => {
    getFindings();
  }, []);

  const getFindings = async () => {
    const labels =
      measurements && measurements[0] && measurements[0].measurements
        ? measurements[0].measurements
            .map(m => {
              if (m.label) {
                let label = m.label.split('(')[0].trim();
                if (label == '...') {
                  return;
                }
                return {
                  label: label,
                  measurementId: m.measurementId,
                };
              }
            })
            .filter(Boolean)
        : [];

    const forRegion = localStorage.getItem('userData');
    const jsonObject = JSON.parse(forRegion);
    const StudyInstanceUID = window.location.href
      .split('/viewer/')[1]
      .split('%5E')[0];
    //const base64 = window.location.href.split('%5E')[1];
    const base64 = getBase64FromUrl();
    const token = localStorage.getItem('token');
    let parsed;
    const user_data = localStorage.getItem('userData');
    if (user_data != 'undefined' && user_data != 'null') {
      parsed = JSON.parse(user_data);
    }

    const searchParams = new URLSearchParams(window.location.search);
    const patientName = searchParams.get('PatientName');

    var url;
    if (
      window.location.href.includes('/xrad/') ||
      window.location.href.includes('/radx/') ||
      window.location.href.includes('/mrc/')
    ) {
      url = `https://rad-test.icenna.com/get_findings2?base64=${base64}&StudyInstanceUID=${StudyInstanceUID}&region=us-east-1`;
    } else {
      url = `${
        parsed.privateServer == false
          ? `https://demo.dcmcloud.com/get_findings2?base64=${base64}&StudyInstanceUID=${StudyInstanceUID}&region=${jsonObject.storageLocation}`
          : `http://59.103.158.165:9004/get_findings2?Patient_Name=${patientName}`
      }`;
    }

    setLoading(true);

    fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === 'success') {
          let description1 = data.description;

          const parser = new DOMParser();
          const doc = parser.parseFromString(description1, 'text/html');
          const uTags = doc.querySelectorAll('u');
          labels.forEach(({ label, measurementId }) => {
            const regex = new RegExp(
              `<u id="dynamicspan-[^"]*">(${label})</u>`,
              'g'
            );
            description1 = description1.replace(
              regex,
              `<u id="dynamicspan-${measurementId}">$1</u>`
            );
          });

          setLoading(false);
          setDescription(description1);
        } else {
          setLoading(false);
          console.error('Error retrieving HTML description:', data.message);
        }
      })
      .catch(error => {
        setLoading(false);
        console.error('Fetch error:', error);
      });
  };

  const labels =
    measurements && measurements[0] && measurements[0].measurements
      ? measurements[0].measurements
          .map(m => {
            if (m.label) {
              let label = m.label.split('(')[0].trim();
              if (label == '...') {
                return;
              }
              return {
                label: label,
                measurementId: m.measurementId,
              };
            }
          })
          .filter(Boolean)
      : [];

  const onDescItemClick = () => {
    setCountOpen(true);
    setEditorContent(description);
    setIsModalOpen(true);
  };

  const onTestClick = measurementId => {
    const measurement = measurements[0].measurements.find(
      m => m.measurementId == measurementId
    );

    if (measurement) {
      onItemClick(null, measurement);
    }
  };

  const handleSave = () => {
    const editor = quillRef.current.getEditor();
    const content = editor.root.innerHTML;
    const updatedContent = addUniqueIdsToTags(content);
    setDescription(updatedContent);
    setIsModalOpen(false);
    (async () => {
      await save_findings(updatedContent);
    })();
  };

  const addUniqueIdsToTags = content => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html');
    const uTags = doc.querySelectorAll('u');
    uTags.forEach((uTag, index) => {
      labels.forEach(({ label, measurementId }) => {
        if (uTag.textContent == label) {
          uTag.id = `dynamicspan-${measurementId}`;
        }
      });
    });

    return doc.body.innerHTML;
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setShowDropdown(false);
  };

  const handleEditorChange = (content, delta, source, editor) => {
    setEditorContent(content);
    if (countOpen == true) {
      setCountOpen(false);
      return;
    }
    //let updatedContent = content;
    const selection = editor.getSelection();
    const cursorPosition = selection ? selection.index : 0;
    const textBeforeCursor = editor.getText(0, cursorPosition);
    const parser = new DOMParser();
    const doc = parser.parseFromString(description, 'text/html');
    const uTags = doc.querySelectorAll('u');

    const pattern = /<u>(.*?)<\/u>/g;
    let match1;
    const matches = [];

    while ((match1 = pattern.exec(content)) !== null) {
      matches.push({
        tag: match1[0],
        name: match1[1],
      });
    }

    const unmatchedMatches = matches.filter(
      match => !labels.some(label => label.label === match.name)
    );
    unmatchedMatches.forEach(item => {
      setEditorContent(content.replace(item.tag, item.name));
    });

    // const uTagPattern = /<u[^>]*>([^<]*)<\/u>*<\/p>$/;
    // const match = uTagPattern.exec(content);
    // if (match) {
    //   uTags.forEach((uTag, index) => {
    //     labels.forEach(({ label, measurementId }) => {
    //       if (uTag.id == 'dynamicspan-' + measurementId) {
    //         setMaintainIds(prevMaintainIds =>
    //           prevMaintainIds.filter(item => item !== measurementId)
    //         );
    //       }
    //     });
    //   });
    //   const uTagWithClosingP = match[0];
    //   const replacedContent = uTagWithClosingP.replace(
    //     /<u>(.*?)<\/u>/,
    //     '<span> </span>'
    //   );
    //   updatedContent = updatedContent.replace(
    //     uTagWithClosingP,
    //     replacedContent
    //   );
    //   setEditorContent(updatedContent);
    // }

    if (textBeforeCursor.endsWith('@')) {
      setShowDropdown(true);
      setMentionIndex(cursorPosition);
      // Get the cursor position
      const range = editor.getBounds(selection);
      setDropdownPosition({
        top: range.bottom + window.scrollY + 100,
        left: range.left + window.scrollX,
      });
    } else {
      setShowDropdown(false);
    }
  };

  const handleLabelClick = measurementId => {
    const measurement = measurements[0].measurements.find(
      id => id.measurementId == measurementId
    );

    const alabel = measurement.label.split('(')[0].trim();
    const emptytext = ' ';
    const newlabel = `<u id="dynamic-span-${measurementId}">${alabel}</u><span>${emptytext}</span>`;
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
      quill.deleteText(mentionIndex - 1, 1);
      quill.clipboard.dangerouslyPasteHTML(mentionIndex - 1, newlabel);
      quill.setSelection(mentionIndex - 1 + alabel.length + emptytext.length);
    }
    setShowDropdown(false);
  };

  const renderDescription = () => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(description, 'text/html');

    const traverseNodes = nodes => {
      return Array.from(nodes).map((node, index) => {
        if (node.nodeName === 'U') {
          const measurementId = node.id.replace('dynamicspan-', '');
          return (
            <u
              key={index}
              id={node.id}
              onClick={() => onTestClick(measurementId)}
              style={{
                cursor: 'pointer',
                textDecoration: 'underline',
                color: 'orange',
              }}
            >
              {node.textContent}
            </u>
          );
        } else if (node.nodeName === 'STRONG') {
          return <b>{node.textContent}</b>;
        } else if (node.nodeName === 'EM') {
          return <i>{node.textContent}</i>;
        } else if (node.nodeName === 'H1') {
          return <span style={{ fontSize: '30px' }}>{node.textContent}</span>;
        } else if (node.nodeName === 'H2') {
          return <span style={{ fontSize: '24px' }}>{node.textContent}</span>;
        } else if (node.nodeName === '#text') {
          return node.textContent;
        } else if (node.nodeName === 'BR') {
          return <br />;
        } else {
          return (
            <p style={{ marginTop: '10px' }} key={index}>
              {traverseNodes(node.childNodes)}
            </p>
          );
        }
      });
    };

    return <div>{traverseNodes(doc.body.childNodes)}</div>;
  };

  const save_findings = async sent_description => {
    try {
      const forRegion = localStorage.getItem('userData');
      const jsonObject = JSON.parse(forRegion);
      const StudyInstanceUID = window.location.href
        .split('/viewer/')[1]
        .split('%5E')[0];
      //const base64 = window.location.href.split('%5E')[1];
      const base64 = getBase64FromUrl();
      const token = localStorage.getItem('token');
      let parsed;
      const user_data = localStorage.getItem('userData');
      if (user_data != 'undefined' && user_data != 'null') {
        parsed = JSON.parse(user_data);
      }

      const searchParams = new URLSearchParams(window.location.search);
      const patientName = searchParams.get('PatientName');

      var url;
      if (
        window.location.href.includes('/xrad/') ||
        window.location.href.includes('/radx/') ||
        window.location.href.includes('/mrc/')
      ) {
        url = `https://rad-test.icenna.com/save_findings?base64=${base64}&StudyInstanceUID=${StudyInstanceUID}&region=us-east-1`;
      } else {
        url = `${
          parsed.privateServer == false
            ? `https://demo.dcmcloud.com/save_findings?base64=${base64}&StudyInstanceUID=${StudyInstanceUID}&region=${jsonObject.storageLocation}`
            : `http://59.103.158.165:9004/save_findings?Patient_Name=${patientName}`
        }`;
      }

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          description: sent_description,
        }),
      });

      const data = await response.json();

      if (data.status !== 'success') {
        console.error('Error saving HTML description:', data.message);
      }
    } catch (error) {
      console.error('Fetch error:', error);
    }
  };

  return (
    <div className="">
      <div>
        <span className="foredit cursor-pointer flex" onClick={onDescItemClick}>
          <Icon name="edit-icon" className="w-5 h-5" />
          <span className="text-white ml-2 text-sm the-edit">
            Edit Description
          </span>
        </span>
      </div>
      <div className="contentt">
        <div className="p-5 text-sm">
          {isLoading == true ? 'Loading...' : renderDescription()}
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleCancel}
        contentLabel="Edit Description"
        className="modall"
        overlayClassName="modall-overlay"
      >
        <span className="flex items-center justify-center text-base font-bold mb-3">
          Edit Description
        </span>
        <div className="editor-container">
          <ReactQuill
            className="for-editor"
            ref={quillRef}
            value={editorContent}
            onChange={handleEditorChange}
            modules={Findings.modules}
            formats={Findings.formats}
          />
          {showDropdown && (
            <div
              className="dropdownn"
              style={{
                top: `${dropdownPosition.top}px`,
                left: `${dropdownPosition.left}px`,
                position: 'absolute',
              }}
            >
              {labels.map((label, index) => (
                <div
                  key={index}
                  className="dropdown-item"
                  onClick={() => handleLabelClick(label.measurementId)}
                >
                  {label.label}
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="modall-buttons">
          <button className="save-button" onClick={handleSave}>
            Save
          </button>
          <button className="cancel-button" onClick={handleCancel}>
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  );
};

Findings.modules = {
  toolbar: [[{ header: '1' }, { header: '2' }], ['bold', 'italic'], ['clean']],
};

Findings.formats = [
  'header',
  'list',
  'bullet',
  'bold',
  'italic',
  'underline',
  'link',
  'align',
];

export default Findings;
