import { Component } from 'react';
import React from 'react';
import PropTypes from 'prop-types';

import SimpleDialog from '../SimpleDialog/SimpleDialog.js';
import './EditDescriptionDialog.css';

export default class EditLabelDialog extends Component {
  static propTypes = {
    location: PropTypes.string,
    measurementData: PropTypes.object.isRequired,
    onCancel: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      location: props.measurementData.location || '',
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.setState({
        location: this.props.location,
      });
    }
  }

  render() {
    return (
      <SimpleDialog
        headerTitle="Edit Label"
        onClose={this.onClose}
        onConfirm={this.onConfirm}
        rootClass="editDescriptionDialog"
      >
        <input
          value={this.state.location}
          className="simpleDialogInput"
          id="location"
          autoComplete="off"
          autoFocus
          onChange={this.handleChange}
        />
      </SimpleDialog>
    );
  }

  onClose = () => {
    this.props.onCancel();
  };

  onConfirm = e => {
    e.preventDefault();
    this.props.onUpdate(this.state.location);
  };

  handleChange = event => {
    this.setState({ location: event.target.value });
  };
}
