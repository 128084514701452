import cornerstone from 'cornerstone-core';
import { MeasurementApi } from '../classes';
import log from '../../log';
import user from '../../user';
import getImageAttributes from '../lib/getImageAttributes';
import getLabel from '../lib/getLabel';
import refreshCornerstoneViewports from '../lib/refreshCornerstoneViewports';
import MeasurementHistory from '../../../../../extensions/cornerstone/src/measurementHistory';
// import { measurementRedoHistoryRemove } from '../../../../../extensions/cornerstone/src';

export default function handleSingleMeasurementAdded({ eventData, tool }) {
  // measurementHistory
  // measurementRedoHistoryRemove();
  let measurementRedoHistory = MeasurementHistory.measurementRedoHistory;
  let measurementHistory = MeasurementHistory.measurementHistory;

  const measurementApi = MeasurementApi.Instance;
  if (!measurementApi) {
    log.warn('Measurement API is not initialized');
  }

  const { measurementData, toolType } = eventData;

  const collection = measurementApi.tools[toolType];

  // Stop here if the tool data shall not be persisted (e.g. temp tools)
  if (!collection) return;

  // Stop here if there's no measurement data or if it was cancelled
  if (!measurementData || measurementData.cancelled) return;

  log.info('CornerstoneToolsMeasurementAdded');

  const imageAttributes = getImageAttributes(eventData.element);
  const measurement = Object.assign({}, measurementData, imageAttributes, {
    lesionNamingNumber: measurementData.lesionNamingNumber,
    userId: user.getUserId(),
    toolType,
  });
  let ins = MeasurementApi.Instance;
  // console.log(measurementApi);
  const addedMeasurement = measurementApi.addMeasurement(toolType, measurement);
  // console.log(addedMeasurement, "addedMeas");
  Object.assign(measurementData, addedMeasurement);

  measurementRedoHistory.forEach((redoHistory, index) => {
    if (redoHistory.SeriesInstanceUID === addedMeasurement.SeriesInstanceUID) {
      measurementRedoHistory[index].measurementHistory = [];
    }
  });

  //NEw Functionality start
  if (measurementHistory.length === 0) {
    // console.log(measurementHistory, 'measurementHistory');
    measurementHistory.push({
      SeriesInstanceUID: addedMeasurement.SeriesInstanceUID,
      measurementHistory: [{ measurementData: addedMeasurement, toolType }],
    });
  } else {
    let updateHistory = false;
    measurementHistory.forEach((histroy, index) => {
      if (addedMeasurement.SeriesInstanceUID === histroy.SeriesInstanceUID) {
        updateHistory = true;
        // console.log(
        //   'measurementHistory',
        //   addedMeasurement.SeriesInstanceUID,
        //   histroy.SeriesInstanceUID
        // );
        measurementHistory[index] = {
          SeriesInstanceUID: addedMeasurement.SeriesInstanceUID,
          measurementHistory: [
            ...measurementHistory[index].measurementHistory,
            { measurementData: addedMeasurement, toolType },
          ],
        };
      }
    });

    if (!updateHistory) {
      measurementHistory.push({
        SeriesInstanceUID: addedMeasurement.SeriesInstanceUID,
        measurementHistory: [{ measurementData: addedMeasurement, toolType }],
      });
    }
  }
  //NEw Functionality end

  // console.log('addedMeasurement', measurementHistory);

  measurementHistory = measurementHistory;

  const measurementLabel = getLabel(measurementData);
  if (measurementLabel) {
    measurementData.labels = [measurementLabel];
  }

  // TODO: This is very hacky, but will work for now
  refreshCornerstoneViewports();

  // TODO: Notify about the last activated measurement

  if (MeasurementApi.isToolIncluded(tool)) {
    // TODO: Notify that viewer suffered changes
  }
}
