import React from 'react';
import cornerstone from 'cornerstone-core';
import cornerstoneTools from 'cornerstone-tools';
import PropTypes from 'prop-types';

import { SpineLabelingModal } from '@dcmcloud/ui';

const CornerstoneSpineLabelingModal = ({ onClose, activeViewportIndex }) => {
  const handleStartLabeling = ({ selectedLabel, direction, excludedLabels }) => {
    const toolName = 'Probe';
    const configuration = {
      textsArray: [selectedLabel, ...getLabelsArray(selectedLabel, direction, excludedLabels)],
    };

    cornerstoneTools.setToolActive(toolName, {
      mouseButtonMask: 1,
      configuration: configuration,
    });

    onClose();
  };

  const getLabelsArray = (startLabel, direction, excludedLabels) => {
    const allLabels = ['C1', 'C2', 'C3', 'C4', 'C5', 'C6', 'C7',
      'T1', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7', 'T8', 'T9', 'T10', 'T11', 'T12',
      'L1', 'L2', 'L3', 'L4', 'L5',
      'S1', 'S2', 'S3', 'S4', 'S5'];

    const startIndex = allLabels.indexOf(startLabel);
    let labelsArray = direction === 'ascend'
      ? allLabels.slice(startIndex + 1)
      : allLabels.slice(0, startIndex).reverse();

    return labelsArray.filter(label => !excludedLabels.includes(label));
  };

  return (
    <SpineLabelingModal
      onClose={onClose}
      onStartLabeling={handleStartLabeling}
    />
  );
};

CornerstoneSpineLabelingModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  activeViewportIndex: PropTypes.number.isRequired,
};

export default CornerstoneSpineLabelingModal;
